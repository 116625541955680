import React, { useEffect, useRef, useState } from 'react'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { postRequest } from '../../../consts/Server/Requests'
import IsLoading from '../../../consts/IsLoading/IsLoading'
import { areaWallet } from './dataAreaWallet'
import MomentDate from '../../../consts/MomentDate'
import { CardCountCustomerReview } from './CardCountCustomerReview'
import Not_Found_Data from '../../../consts/Not_Found_Data'
function convertData(graphData: any) {
    return {
        categories: graphData?.monthWiseProgress?.data?.map((item: any) => item?.name),
        series: [
            {
                name: "Value",
                data: graphData?.monthWiseProgress?.data?.map((item: any) => item?.value)
            }
        ]
    }
}

const AreaManagerCustomerReview = ({ filterDataOutSource }: any) => {
    const [pending, setPending] = useState<boolean>(false)
    const [data, setData] = useState<any>([])

    const dateFromRefStartDate = useRef<any>(null);
    const dateFromRefEndDate = useRef<any>(null);
    async function getAreaMangerWalletData(payload: { startDate: string, endDate: string, supervisorId: any }) {
        const result = await postRequest(URL_EndPoints(null)?.getAllAMCustomerData, payload, setPending)
        setData(result?.data?.data || [])
    }

    useEffect(() => {

        if (filterDataOutSource?.startDate) {
            dateFromRefStartDate.current.value = filterDataOutSource?.startDate || MomentDate()
            dateFromRefStartDate.current.max = filterDataOutSource?.startDate || MomentDate()
            dateFromRefEndDate.current.value = filterDataOutSource?.endDate || MomentDate()
            dateFromRefEndDate.current.max = filterDataOutSource?.endDate || MomentDate()
        }
        if (filterDataOutSource?.startDate && filterDataOutSource?.supervisorId) {
            setPending(true)
            getAreaMangerWalletData(filterDataOutSource)
        }
        else if (filterDataOutSource?.startDate || filterDataOutSource?.supervisorId) {
            setPending(true)
            getAreaMangerWalletData(filterDataOutSource)
        }
        else {
            const startDate = dateFromRefStartDate.current.value || MomentDate(0)
            const endDate = dateFromRefEndDate.current.value || MomentDate(0)
            setPending(true)
            getAreaMangerWalletData({
                startDate,
                endDate,
                supervisorId: null,
            })
        }

    }, [filterDataOutSource?.startDate, filterDataOutSource?.supervisorId])


    const handleTabClick = () => {

    }

    const searchHandler = ()=>{
        const startDate = dateFromRefStartDate.current.value || MomentDate(0)
        const endDate = dateFromRefEndDate.current.value || MomentDate(0)
        setPending(true)
        getAreaMangerWalletData({
            startDate,
            endDate,
            supervisorId: filterDataOutSource?.supervisorId,
        })
    }

    return (
        <>
            <div className='mt-2 mb-4 col-12 d-flex justify-content-end '>
                <div className='d-flex flex-column me-2'>
                    <span className='mb-2 text-muted'>Start Date</span>
                    <input type="date" ref={dateFromRefStartDate}  name="startDate" className='form-select col-4' style={{ width: '200px' }} />
                </div>
                <div className='d-flex flex-column me-2'>
                    <span className='mb-2 text-muted'>End Date</span>
                    <input type="date" ref={dateFromRefEndDate}  name="endDate" className='form-select col-4' style={{ width: '200px' }} />
                </div>
                <div className='d-flex justify-content-center align-items-center me-2 mt-6'>
                    <button className='btn btn-sm btn-primary' onClick={searchHandler}>Submit</button>
                </div>
            </div>

            {
                <div className="container-fluid p-0">
                    {
                        pending ?
                            <IsLoading /> : data?.length == 0 ? <Not_Found_Data headerTitle="Area Manager Customer Review Data Not Found" /> :
                                <div className='row p-0' >
                                    {data.map((element: any) => {
                                        let convertedDataValue = convertData(element?.data)
                                        return <div className="col-3 p-1 mb-2 ">
                                            <CardCountCustomerReview className='border border-primary' color='primary' data={element?.data} chartData={convertedDataValue} handleTabClick={handleTabClick} />
                                        </div>
                                    })}
                                </div>
                    }

                </div>
            }


        </>
    )
}

export default AreaManagerCustomerReview
