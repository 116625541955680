/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import React from "react"
import Not_Found_Data from '../../../../../consts/Not_Found_Data'
import HoverToolTips from '../../../../../consts/CustomToolTips/OnHoverToolTips/HoverToolTips'
import moment from 'moment'
import AudioPalyerModel from '../../../../../consts/BootrapDropdown/AudioPalyerModel'
import AudioPlayer from '../../../../../consts/AudioPlayer'
import { fetchCallHistoryUtil } from '../../../../../consts/components/Utility'
type Props = {
    call_log_cusomerid?: any
}

const CallogsBody: FC<Props> = ({ call_log_cusomerid }) => {
    const [loading, setloading] = React.useState(false)
    const [audio, setAudio] = useState<any>("");
    const [toggle, setToggle] = React.useState(false)
    const [ShowDataRefs, setShowDataRefs] = React.useState(0)
    const [palaying, setpalaying] = useState<any>(null);

    const handleClickToPlay = (url: any) => {
        setAudio(url)
    };
    const [callHistoryData, setcallHistoryData] = React.useState<any>([])

    async function invokedApi() {
        // setloading(true)
        // const ticketListData = await postRequest(URL_EndPoints()?.getAllTicketsByCustomerId, {
        //     customerid: call_log_cusomerid
        // }, setloading)
        // setcallHistoryData(ticketListData?.data?.callHistory)
        fetchCallHistoryUtil([call_log_cusomerid], setcallHistoryData, setloading, "")
    }
    React.useEffect(() => {

        if (call_log_cusomerid) {
            invokedApi()
        }
        
    }, [call_log_cusomerid])
  
    const handlChange = (ShowData: any) => {
        setToggle((prev) => prev === true && ShowDataRefs === ShowData ? false : true)
        setShowDataRefs(ShowData)
    }

    const hanlePause = (event: any, url: any) => {

        if (url == audio) {
            setpalaying(false)

        }
    }
    const handlePlay = (event: any, url: any) => {

        setpalaying(url === audio && true)

    }
    return (
        <>
            {
                loading ? <div className='d-flex align-items-center justify-content-center h-75 flex-column'>
                    <div className='spinner-border mr-15' role='status'></div>
                    <h4 className='fw-bold'>Loading...</h4>
                </div> :
                    callHistoryData?.length > 0 ?
                        <>
                            <div className='table'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fs-8 fw-bold'>
                                            <th className='min-w-25px'>Recording</th>
                                            <th className='min-w-150px'>Call Duration</th>
                                            <th className='min-w-150px'>Start - End Time </th>
                                            <th className='min-w-140px'>Call Status</th>
                                            <th className='min-w-120px'>Call From </th>
                                            <th className='min-w-100px'>Call To </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            callHistoryData?.map((element: any) => {
                                                let callConersation: any = element?.ConversationDuration / 60
                                                let times: any = callConersation?.toString()?.split('.')
                                                let minute = times?.length > 0 ? times[0] : 0
                                                let seconds = times?.length > 1 ? times[1]?.toString()[0] : 0
                                                return (
                                                    <tr>
                                                        {
                                                            element?.RecordingUrl ? <td onClick={() => handleClickToPlay(element?.RecordingUrl)} id="plays">
                                                                <AudioPalyerModel ParentData={{ element, handlePlay, hanlePause, palaying }} Components={AudioPlayer} audio={audio === element?.RecordingUrl ? audio : ""} setAudio={setAudio} ></AudioPalyerModel>
                                                            </td> : <td><button className='btn btn text-dark  ms-1'><i className="bi bi-x-circle text-danger fs-1"></i></button></td>
                                                        }
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='d-flex justify-content-start flex-column'>
                                                                    <span className={`text-${element?.ConversationDuration > 0 ? "success" : "danger"} text-hover-primary fs-8 fw-bold`}>
                                                                        {element?.ConversationDuration == 60 ? "1 Min" : element?.ConversationDuration == 0 ? "0 Sec" : element?.ConversationDuration > 0 && element?.ConversationDuration < 60 ? `${element?.ConversationDuration} Sec` : times?.length > 0 ? `${minute} Min ${seconds} Sec` : "0 Sec"}
                                                                    </span>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className={`text-dark text-hover-primary d-block fs-8 fw-bold`}>
                                                                {moment(element?.StartTime).format("HH:mm:ss")} {" - "}
                                                                {moment(element?.EndTime).format("HH:mm:ss")}
                                                            </span>
                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                {moment(element?.StartTime).format("DD-MM-YYYY")}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`text-${element?.Status === "completed" ? "success" : element?.Status === "failed" ? "warning" : "primary"}  text-hover-primary d-block fs-8 fw-bold`}>
                                                                {element?.Status}
                                                            </span>
                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className='  d-block fs-8 fw-bold'>
                                                                <HoverToolTips uniqueId={element.id} title={element?.FromDetails?.name} showData={element?.From} classeTitle={"text-hover-dark text-primary me-2"} classeShowData={"text-danger"} handlChange={handlChange} ShowDataRefs={ShowDataRefs} toggle={toggle} />
                                                                <span className='text-hover-dark text-muted'>  {element?.FromDetails?.type}</span>
                                                            </div>
                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className='text-success text-hover-primary d-block fs-8 fw-bold'>
                                                                <div className='  d-block fs-8 fw-bold'>
                                                                    <HoverToolTips uniqueId={element.id + 1} title={element?.ToDetails?.name} showData={element?.To} classeTitle={"text-hover-dark text-primary me-2"} classeShowData={"text-danger"} handlChange={handlChange} ShowDataRefs={ShowDataRefs} toggle={toggle} />
                                                                    <span className='text-hover-dark text-muted'>  {element?.ToDetails?.type}</span>
                                                                </div>
                                                            </span>
                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                        :
                        <Not_Found_Data headerTitle={"No Call Logs Found"} ></Not_Found_Data>
            }
        </>
    )
}
export { CallogsBody }
