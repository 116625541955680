import React, { FC, useEffect, useState } from 'react'
import SingleSelectSearchDetailsDefault from '../../consts/Select/SingleSelectSearchDetailsDefault'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
const AllotmentFilter = ({ ParentData }: any) => {

  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>

      <div className='col-12 mb-10' style={{ position: "relative" }}>
        <span className='rounded badge badge-light-dark' style={{ position: "absolute", top: -12, zIndex: 1, left: "50%", transform: "translate(-50%, -50%)" }}>Rating Range</span>
        <div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
          <input className='form-control' type="number" name="rating_sval" id="rating_sval"
            autoComplete='off'
            onChange={ParentData?.handleChangeInputData}
            placeholder='Rating Start...'
          />
          <input className='form-control' type="number" name="rating_eval" id="rating_eval"
            autoComplete='off'
            onChange={ParentData?.handleChangeInputData}
            placeholder='Rating End...'

          />
        </div>
      </div>

      <div className='col-12 mb-10' style={{ position: "relative" }}>
        <span className='rounded badge badge-light-dark' style={{ position: "absolute", top: -12, zIndex: 1, left: "50%", transform: "translate(-50%, -50%)" }}>Activation Date</span>
        <div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>

          <input className='form-control' type="date" name="activation_sdate" id="activation_sdate"
            autoComplete='off'
            onChange={ParentData?.handleChangeInputData}

          />

          <input className='form-control' type="date" name="activation_edate" id="activation_edate"
            autoComplete='off'
            onChange={ParentData?.handleChangeInputData}

          />

        </div>
      </div>


      <div className='col-12 mb-2' >
        <select
          className='form-select'
          name='payout_type'
          onChange={ParentData?.handleChangeInputData}
        >
          <option value={""}>Select Payout Type</option>
          {[
            { id: 1, name: 'Weekly' },
            { id: 2, name: 'Monthly' },
          ].map((item: any) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>

      <div className='col-12 mb-4' key={4343232345} >
        <SingleSelectSearchDetailsDefaultDyanmic
          handleChangeInputData={ParentData?.handleChangeInputData}
          HeaderTitle='Select Champ Status'
          SelectData={ParentData?.operationStatuses || []}
          DynamicKeys={["name"]}
          id={"id"}
          name={"status_id"}
          targetValue={"value"}
          defaultData={null}
        ></SingleSelectSearchDetailsDefaultDyanmic>
      </div>


    </div>
  )
}
export { AllotmentFilter }
