import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../Server/Requests'
import { toast } from 'react-toastify'
import TagsInputs from './Tags/TagsInputs'
import IsBlockAlert from '../../SweetAlert/IsBlockAlert';
import PhoneNumberUpdate from './PhoneNumberUpdate'
import ApproveCoinUsageAlert from '../../SweetAlert/ApproveCoinUsageAlert'
import clsx from 'clsx'
import "./custom.css"
import UpdateSocietyForm from './Forms/UpdateSocietyForm'
import WhatsAppNumberUpdate from './WhatsAppNumberUpdate'
import AdminYesNoAlertAction from '../../../modules/admins/adminComponents/AdminYesNoAlertAction'
import { adminQueryGenerator, getName, useAdminManagementPermission } from '../../../modules/admins/adminUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import BulkAssigAgentCustomerAgent from '../../../modules/customerAssignedAgents/BulkAssigAgentCustomerAgent'
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant'
import AdminForm from '../../../modules/admins/adminForms/AdminForm'
const Customer_Info = ({ customerDetailData, supervisorData, jobRating }: any) => {
    const { permissionWrapperContextUtil } = useAdminManagementPermission()

    const userid: any = JSON.parse(localStorage.getItem("user") || "")
    const [ShowBlockForm, setShowBlockForm] = useState(false)
    const [PhoneNumberUpdateShow, setPhoneNumberUpdateShow] = useState(false)
    const ticketDataRedux = useSelector((store: any) => store.ChatReducers.TicketData)
    const [states, setStates] = useState<string[]>([])
    const [show, setShow] = useState<boolean>(false)
    const [selectedTag, setselectedTag] = useState<any>("")
    const [checkboxs, setcheckbox] = useState<boolean>(false)
    const [Renders, setRenders] = useState<any>(null)
    const [phoneNumber, setPhoneNumber] = useState<any>(null)
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [RowData, setRowData] = useState<any>(customerDetailData)
    const [showModal, setShowModal] = useState<any>(false)
    const [showApproveCoinUsageForm, setShowApproveCoinUsageForm] = useState<any>(false)
    const blockunblockHandler = () => {
        if (customerDetailData?.id) {
            setShowBlockForm(true)
            setRenders(Math.random())
        } else {
            toast.error("Sorry not allowed to perform this action 0")
        }
    }
    const approveCoinHandler = () => {
        if (customerDetailData?.id) {
            setShowApproveCoinUsageForm(true)
            setRenders(Math.random())
        } else {
            toast.error("Sorry not allowed to perform this action 0")
        }
    }
    const PhoneNummberUpdate = async () => {
        if (customerDetailData?.id) {
            setLoader(true)
            const phoneNumberUpdates: any = await postRequest(URL_EndPoints(null)?.updateCustomerPhone, {
                customerid: customerDetailData?.id,
                newPhoneNumber: phoneNumber,
                userid: userid,
            }, setLoader)
            if (phoneNumberUpdates?.data?.status === 200) {
                toast.success(phoneNumberUpdates?.data?.msg)
                customerDetailData.phone = phoneNumber
                setRowData(customerDetailData)
                setPhoneNumberUpdateShow(false)
                setLoader(false)
                setDisable(null)
            }
            else {
                toast.error(phoneNumberUpdates?.data?.msg)
            }
        }
    }

    const [whatsAppNumberUpdateShow, setWhatsAppNumberUpdateShow] = useState(false)
    const [whatsAppNumber, setWhatsAppNumber] = useState(RowData?.whatsapp_number)
    const [whatsAppUpdateLoading, setWhatsAppUpdateLoading] = useState(false)

    const handleWhatsAppUpdateClose = () => {
        setWhatsAppNumberUpdateShow(false)
    }

    const handleWhatsAppUpdate = async (event: any) => {
        event.preventDefault()
        setWhatsAppUpdateLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateCustomerWhatsAppNumber, {
            customerId: customerDetailData?.id,
            number: whatsAppNumber,
        }, setWhatsAppUpdateLoading)
        if (response?.data?.status === 200) {
            customerDetailData.whatsapp_number = whatsAppNumber
            setRowData(customerDetailData)
            setWhatsAppNumberUpdateShow(false)
            toast.success(response.data.message)
        } else {
            toast.info(response.data.message)
        }
        setWhatsAppUpdateLoading(false)
    }

    useEffect(() => {
        setRowData(customerDetailData)
    }, [customerDetailData])
    const handleEditTags = (params: string) => {
        if (customerDetailData?.id) {
            setDisable(null)
            if (params === "Private Tags") {
                setStates(customerDetailData?.private_tag === "" || customerDetailData?.private_tag == null ? [] : customerDetailData?.private_tag.split(","))
                setselectedTag(params)
            }
            else {
                setStates(customerDetailData?.public_tag === "" || customerDetailData?.public_tag == null ? [] : customerDetailData?.public_tag.split(","))
                setselectedTag(params)
            }
            setShow(true)
        } else {
            toast.error("Sorry not allowed to perform this action 1")
        }
    }
    const closeBtn = () => {
        setShow(false)
        setPhoneNumberUpdateShow(false)
        setShowModal(false)
    }
    const Save = async () => {
        let TagData;
        if (checkboxs) {
            let arr: any = []
            if (ticketDataRedux?.id) {
                states?.forEach((ele: any, index: number, array: any) => {
                    if (array.length - 1 === index) {
                        let str = ele
                        str = str + `_${ticketDataRedux?.id ? ticketDataRedux?.id : ""}`
                        arr.push(str)
                    }
                    else {
                        arr.push(ele)
                    }
                })
            }
            // else {
            //     toast.error("Ticket id is missing")
            // }
            TagData = arr
        }
        else {
            TagData = states
        }
        if (customerDetailData?.id) {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.addTagCustomer,
                { customerid: customerDetailData?.id, tagtype: selectedTag == "Private Tags" ? "private" : "public", tag: TagData ? TagData?.join(",") : "" }, setLoader)
            if (response?.data?.status == 200) {
                let updatedData;
                if (selectedTag === "Private Tags") {
                    customerDetailData.private_tag = TagData ? TagData?.join(",") : ""
                    updatedData = customerDetailData
                }
                else {
                    customerDetailData.public_tag = TagData ? TagData?.join(",") : ""
                    updatedData = customerDetailData
                }
                setRowData(updatedData)
                toast.success(response?.data?.message)
                closeBtn()
                setDisable(200)
            } else {
                toast.error(response?.data?.message)
                closeBtn()
            }
        }
    }

    const setUpdatedCoinUsag = (payload: any) => {
        customerDetailData.approve_coin_usage = payload?.approve_coin_usage
        setRowData(customerDetailData)
    }

    const setUpdatedIsBlock = (payload: any) => {
        customerDetailData.is_deleted = payload?.isblock
        setRowData(customerDetailData)
    }

    const setUpdatedServiceCharge = (payload: any) => {
        customerDetailData.apply_service_charge = customerDetailData.apply_service_charge == 0 ? 1 : 0
        setRowData(customerDetailData)
    }

    let color = getName(RowData?.customerAssignedAgent) ? "success" : "danger"

    const [showTransferAgent, setShowTransferAgent] = useState(false)
    const [showAssignPackage, setShowAssignPackage] = useState(false)
    const [showServiceCharge, setShowServiceCharge] = useState<any>(null)
    const [packagesData, setPackages] = useState([])
    let packageColor = customerDetailData.customerPackageDetail ? "success" : "danger"
    const serviceMessage = customerDetailData?.apply_service_charge == 0 ? `You want to apply service charge ?` : `You don't want to apply service charge ?`
    const setUpdatedPackage = (payload: any) => {
        const update: any = packagesData?.find((ele: any) => ele?.id == payload?.packageID)
        if (update) {
            customerDetailData.customerPackageDetail = customerDetailData.customerPackageDetail ? { ...customerDetailData.customerPackageDetail } : {}
            customerDetailData.customerPackageDetail.name = update?.name
            customerDetailData.customerPackageDetail.id = update?.id
        }
        setRowData(customerDetailData)
    }

    const setUpdatedCx = (row: any) => {
        setRowData(row)
    }

    let defaultPOC = RowData?.customerAssignedAgent?.first_name ? {
        label: RowData?.customerAssignedAgent?.first_name + " " + RowData?.customerAssignedAgent?.last_name,
        value: null
    } : null

    const getActivePackages = async () => {
        let returnType = "new_package"
        const packagesData = await getRequest(URL_EndPoints(adminQueryGenerator("getActivePackageDetails", { returnType: returnType }))?.getActivePackageDetails, null)
        setPackages(packagesData?.data?.data)
    }

    useEffect(() => {
        getActivePackages()
    }, [])
    return (
        < >

            <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'}>
                <div
                    style={{ maxHeight: "calc(100vh-300px)" }}
                    className={clsx('scroll-y me-n5')}
                    data-kt-scroll='true'
                    data-kt-scroll-dependencies={'#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'}
                    data-kt-scroll-wrappers={'#kt_content, #kt_chat_messenger_body'}
                    data-kt-scroll-offset={'-2px'}
                >
                    {
                        // <div className="px-4 mt-3 p-3"  >
                        // <div className='px-4 mt-3 p-3 d-flex flex-wrap align-items-start bg  rounded p-5 mr-4'  >
                        <div className='flex-grow-1 me-2 margins px-4 p-2 '>
                            <p>
                                Name : {getName(RowData) || "Not Set"}
                            </p>
                            <p>
                                POC : <span className={`badge badge-${color}`}>{getName(RowData?.customerAssignedAgent) || "Not Assigned"}  </span>
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-poc-update"]}>
                                    <FontAwesomeIcon icon={faEdit} className='ms-4 text-muted cursor-pointer' onClick={() => setShowTransferAgent(true)} />
                                </PermissionContextProvider>
                            </p>
                            <p>
                                Assigned Package : <span className={`badge badge-${packageColor}`}>{RowData?.customerPackageDetail?.name ? RowData?.customerPackageDetail?.name : "Not Assigned"}  </span>
                                {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-assign-package"]}> */}
                                <FontAwesomeIcon icon={faEdit} className='ms-4 text-muted cursor-pointer' onClick={() => setShowAssignPackage(true)} />
                                {/* </PermissionContextProvider> */}
                            </p>
                            <p>
                                Service Charge : <span className={`badge badge-${packageColor}`}>
                                    {RowData?.apply_service_charge == 1 ? "Applied" : "Not Applied"}
                                </span>

                                <FontAwesomeIcon icon={faEdit} className='ms-4 text-muted cursor-pointer' onClick={() => setShowServiceCharge(Math.random())} />

                            </p>
                            <p>
                                Email : {RowData?.email ? RowData?.email : "NA"}
                            </p>
                            <p>
                                <span className='me-1'> Phone: {RowData?.phone} </span>
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-phone-number-update"]}>
                                    <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => setPhoneNumberUpdateShow(true)}></i>
                                </PermissionContextProvider>
                            </p>
                            <p>
                                <span className='me-1'> WhatsApp: {RowData?.whatsapp_number} </span>
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-whatsapp-number-update"]}>
                                    <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => setWhatsAppNumberUpdateShow(true)}></i>
                                </PermissionContextProvider>
                            </p>
                            <p>
                                City : {RowData?.custcity ? RowData?.custcity.city_name : "NA"}
                            </p>
                            <p>
                                Supervisor Name : {supervisorData?.first_name ? supervisorData?.first_name + " " + supervisorData?.last_name : "NA"}
                            </p>
                            <p>
                                Job Site: {RowData?.societyid > 0 ? 'Society Customer' : "Individual Customer"}
                            </p>

                            <p>
                                <span className='me-1'>  Society Name: </span>
                                {RowData?.society != null ? RowData?.society?.name : "NA"}
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-society-name-update"]}>
                                    <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => setShowModal(true)}></i>
                                </PermissionContextProvider>

                            </p>

                            <p>
                                Coins: <span className="badge badge-square badge-info">{RowData?.coins ? RowData?.coins : "0"}</span>
                            </p>
                            <p>
                                Coins Value: <span className="badge badge-square badge-info">{RowData?.coins_value ? RowData?.coins_value : "0"}</span>
                            </p>
                            <p>
                                Wallet: Coins: <span className="badge badge-square badge-info">{RowData?.wallet_coins ? RowData?.wallet_coins : "0"}</span>
                            </p>
                            <p>
                                Is Active: {RowData?.active_status > 0 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">InActive</span>}
                            </p>
                            <p>
                                Average Job Ratings : <span className="badge badge-success">{jobRating?.averageRate}</span>
                            </p>
                            <p>
                                Last Updated App Version: <span className="badge badge-success">{RowData?.last_updated_app_version}</span>
                            </p>
                            <p>
                                <span className='me-1'> Is Block: </span>
                                {RowData?.is_deleted == 0 ? <span className="badge badge-success">Not Bloked</span> : RowData?.is_deleted == 1 ? <span className="badge badge-danger">Blocked</span> : "Not Set"}
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-block-status-update"]}>
                                    <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => blockunblockHandler()}></i>
                                </PermissionContextProvider>

                            </p>
                            <p>
                                <span className='me-1'> Approve Coin Usage: </span>
                                {RowData?.approve_coin_usage == 0 ? <span className="badge badge-danger">Not Allow</span> : RowData?.approve_coin_usage == 1 ? <span className="badge badge-success">Allowed</span> : "Not Set"}
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-approve-coin-usage"]}>
                                    <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => approveCoinHandler()}></i>
                                </PermissionContextProvider>
                            </p>
                            <div className='d-flex mb-3'>
                                Private Tags:
                                <div className='d-flex'>
                                    {
                                        RowData?.private_tag === "" || RowData?.private_tag == null ? <span className='me-1 badge badge-light-danger  fw-bold'> <span className='me-1'>NTA</span>

                                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-private-tag-update"]}>
                                                <i className="fas fa-edit ms-3 text-success  cursor-pointer" onClick={() => handleEditTags("Private Tags")}></i>
                                            </PermissionContextProvider>
                                        </span>
                                            :
                                            RowData?.private_tag.split(",").map((ele: any, index: number, arr: any) => {
                                                if (index == arr?.length - 1) return (
                                                    <div className='d-lex'>
                                                        <span className="me-1 badge badge-light-danger  fw-bold">{ele}</span>
                                                        <span className="me-1 badge badge-light-danger  fw-bold">
                                                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-private-tag-update"]}>
                                                                <i className="fas fa-edit ms-3 text-success  cursor-pointer" onClick={() => handleEditTags("Private Tags")}></i>
                                                            </PermissionContextProvider>
                                                        </span>
                                                    </div>
                                                )
                                                else return (
                                                    <span className="me-1 badge badge-light-danger  fw-bold">{ele}</span>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                            <div className='d-flex'>
                                Public Tags:
                                <div className='d-flex'>
                                    {
                                        RowData?.public_tag === "" || RowData?.public_tag == null ? <span className='me-1 badge badge-light-success fs-8 fw-bold'> <span className='me-1'> No Tags Available</span>
                                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-public-tag-update"]}>
                                                <i className="fas fa-edit ms-3 text-success  cursor-pointer" onClick={() => handleEditTags("Public Tags")}></i>
                                            </PermissionContextProvider>
                                        </span>
                                            :
                                            RowData?.public_tag.split(",").map((ele: any, index: number, arr: any) => {
                                                if (index == arr?.length - 1) return (
                                                    <div className='d-lex'>
                                                        <span className="me-1 badge badge-light-danger fs-8 fw-bold">{ele}</span>
                                                        <span className="me-1 badge badge-light-danger fs-8 fw-bold">
                                                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-information-public-tag-update"]}>
                                                                <i className="fas fa-edit ms-3 text-success  cursor-pointer" onClick={() => handleEditTags("Public Tags")}></i>
                                                            </PermissionContextProvider>

                                                        </span>
                                                    </div>
                                                )
                                                else return (
                                                    <span className="me-1 badge badge-light-danger fs-8 fw-bold">{ele}</span>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                            {/* <TagsInputs FilteredData={{
                                disable,
                                loader,
                                setStates,
                                states,
                                handleClose: closeBtn,
                                selectedTag,
                                Save,
                                show,
                                checkboxs,
                                setcheckbox,
                            }} handleCloseForm={closeBtn}></TagsInputs>
                            <PhoneNumberUpdate FilteredData={{
                                disable,
                                loader,
                                setPhoneNumber,
                                phoneNumber,
                                handleClose: closeBtn,
                                PhoneNummberUpdate,
                                PhoneNumberUpdateShow,
                            }} handleCloseForm={closeBtn}></PhoneNumberUpdate> */}
                        </div>
                        // </div>
                        // </div>
                    }
                </div>
            </div>


            {
                showAssignPackage ? <AdminForm
                    show={showAssignPackage}
                    setShow={setShowAssignPackage}
                    refreshNow={null}
                    toastMessage="msg"
                    formData={[
                        {
                            HeaderTitle: 'Select Package',
                            filterApi: 'staticFormData',
                            label: {
                                show: false,
                                name: 'Select Package',
                            },
                            valueType: 'select',
                            inputType: '',
                            displayFields: ['name'],
                            valueField: 'id',
                            name: 'packageID',
                            from: '',
                            to: '',
                            isRequired: false,
                            errMessage: 'Package is required !',
                            searchable: false,
                            wa_number_id: null,
                            userType: null,
                        },
                    ]}
                    staticFormData={packagesData}
                    initialData={{
                        customerID: customerDetailData?.id,
                        packageID: customerDetailData?.customerPackageDetail?.id,
                    }}

                    setUpdatedData={setUpdatedPackage}
                    prevInitialData={customerDetailData?.customerPackageDetail?.id ? { item: { label: customerDetailData?.customerPackageDetail?.name, value: customerDetailData?.customerPackageDetail?.id } } : null}
                    dynamicApiData={{
                        getActivePackageDetails: {
                            url: 'getActivePackageDetails',
                            method: 'GET',
                            isQuery: null,
                            isParams: null,
                            isBody: null,
                        }
                    }}
                    keyName="updatePackage"
                    formTitle={`Assign Package`}
                    formSubmitApiUrl="assignPackageVariant"
                /> : <></>
            }



            {
                showTransferAgent ? <BulkAssigAgentCustomerAgent
                    showAssignPopUp={showTransferAgent}
                    setShowAssignPopUp={setShowTransferAgent}
                    filteredSelectedRows={[customerDetailData?.id]}
                    setFilteredSelectedRows={null}
                    selectedRowsCount={[1]}
                    toggledClearRows={null}
                    setToggleClearRows={null}
                    handleRefresh={null}
                    setUpdatedCx={setUpdatedCx}
                    row={customerDetailData}
                    defaultData={defaultPOC}
                /> : <></>

            }





            <TagsInputs FilteredData={{
                disable,
                loader,
                setStates,
                states,
                handleClose: closeBtn,
                selectedTag,
                Save,
                show,
                checkboxs,
                setcheckbox,
            }} handleCloseForm={closeBtn}></TagsInputs>

            <PhoneNumberUpdate FilteredData={{
                disable,
                loader,
                setPhoneNumber,
                phoneNumber,
                handleClose: closeBtn,
                PhoneNummberUpdate,
                PhoneNumberUpdateShow,
            }} handleCloseForm={closeBtn} />

            <WhatsAppNumberUpdate FilteredData={{
                setRowData,
                whatsAppNumberUpdateShow,
                handleWhatsAppUpdateClose,
                setWhatsAppNumber,
                whatsAppNumber,
                whatsAppUpdateLoading,
                handleWhatsAppUpdate,
            }} />

            {/* {
                ShowBlockForm ?
                    <IsBlockAlert render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
                        customerid: customerDetailData?.id,
                        isblock: customerDetailData?.is_deleted,
                    }}
                        setIsDeleted={setRowData}
                        customerDetailData={customerDetailData}
                        setShowBlockForm={setShowBlockForm}
                        title={`Are you sure want to ${customerDetailData?.is_deleted == 0 ? "Block" : "Unblock"} ?`} /> : <></>
            } */}

            {
                ShowBlockForm ? <div key={customerDetailData?.id + "_" + customerDetailData?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to ${customerDetailData?.is_deleted == 0 ? "Block" : "Unblock"} ?`}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            customerid: customerDetailData?.id,
                            isblock: customerDetailData?.is_deleted === 1 ? 0 : 1,
                        }}
                        show={Renders}
                        setShow={setShowBlockForm}
                        setUpdatePrevState={setUpdatedIsBlock}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="blockUnblockCustomer"
                        row={customerDetailData}
                    />
                </div> : <></>
            }

            {/* {
                showApproveCoinUsageForm ?
                    <ApproveCoinUsageAlert render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
                        customerid: customerDetailData?.id,
                        approve_coin_usage: customerDetailData?.approve_coin_usage ,
                    }}
                        setIsDeleted={setRowData}
                        customerDetailData={customerDetailData}
                        setShowApproveCoinUsageForm={setShowApproveCoinUsageForm}
                        title={`Are you sure want to ${customerDetailData?.approve_coin_usage == 0 ? "Approve" : "Revoke"} ?`} /> : ""
            } */}

            {
                showServiceCharge ? <div key={customerDetailData?.id + "_" + customerDetailData?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={serviceMessage}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            customerID: customerDetailData?.id,
                        }}
                        show={showServiceCharge}
                        setShow={setShowServiceCharge}
                        setUpdatePrevState={setUpdatedServiceCharge}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="toggleServiceCharge"
                        row={customerDetailData}
                    />
                </div> : <></>
            }

            {
                showApproveCoinUsageForm ? <div key={customerDetailData?.id + "_" + customerDetailData?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to ${customerDetailData?.approve_coin_usage == 0 ? "Approve" : "Revoke"} ?`}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            customerid: customerDetailData?.id,
                            approve_coin_usage: customerDetailData.approve_coin_usage = customerDetailData?.approve_coin_usage === 1 ? 0 : 1,
                        }}
                        show={Renders}
                        setShow={setShowApproveCoinUsageForm}
                        setUpdatePrevState={setUpdatedCoinUsag}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="updateApproveCoinUsage"
                        row={customerDetailData}
                    />
                </div> : <></>
            }

            {
                showModal ? <UpdateSocietyForm showModal={showModal} handleClose={closeBtn} row={customerDetailData} setRowData={setRowData} /> : <></>
            }
        </ >
    )
}
// { customerid, isblock }
export default Customer_Info
