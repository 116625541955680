import React, { useEffect, useState } from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import { SubscriptionsBody } from './SubscriptionsBody'
import clsx from 'clsx';
import "./custom.css"
{/* -xl-auto g-xl-auto col-auto col-md-auto col-xl-auto col-lg-auto */ }
// 👍
const SubcriptionsDataList = ({ SubcriptionsDataList, eventChenged, SubcriptionsDataLists, random ,ctAddresses}: any) => {


  const [activeColor, setActivecolor] = useState<any>(null)
  const [SubscrptionsData, setSubscrptionsData] = useState<any>([])
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setSubscrptionsData([])
    setActivecolor(null)
  }, [eventChenged])
  const FilterData = (array: any, index: number) => {
    setActivecolor(index)
    setSubscrptionsData(random === "active" ? array?.activeSubscription : array?.subscriptionHistory)
    setLoader(activeColor === index ? loader : true)

    setTimeout(() => {
      setLoader(false)
    }, 500)
  }



  return (
    <>
      <div className='row d-flex flex-wrap justify-content-center align-items-center px-1 mb-4 mt-3 '>
        {
          SubcriptionsDataLists?.map((data: any, index: number) => {
            return <>
              <span
                className={`border card col me-2 mb-2 text-${activeColor == index ? "white" : "dark"} fw-bold text-center  p-1 ms-2 cursor-pointer text-hover-white bg-hover-primary bg-${activeColor == index ? "primary" : ""}`} onClick={() => FilterData(data, index)}>{data?.vehicleBrand?.name} - {data?.vehicleno}</span>
            </>
          })
        }
      </div>
      <div className=' customer-detail-bodys' id={'kt_chat_messenger_body'} >
        <div
          // style={{ maxHeight: SubscrptionsData?.length !== 0 ? "550px" : "" }}
          className={clsx('scroll-y me-n5 pe-5  max')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
        >

          {
         <div className='margins'>
              {SubscrptionsData?.length > 0 ? (
                SubscrptionsData?.map((element: any, index: number, arr:any) => {
                  
                  return (
                    <SubscriptionsBody
                      className='card-xl-stretch mb-xl-8'
                      image='abstract-4.svg'
                      title={element.packageDetail?.name}
                      subscriptions={element}
                      index={index}
                      random={random}
                      description='Create a headline that is informative<br/>and will capture readers'
                      lengths={arr?.length}
                      ctAddresses={ctAddresses}
                    />
                  )
                })
              ) : (
                <Not_Found_Data headerTitle={SubcriptionsDataLists?.length === 0 ? 'No Subscriptions Found' : "Select Vehicle"}></Not_Found_Data>
              )}
            </div>
          }
        </div>
      </div>
    </>
  )
}
export default SubcriptionsDataList
