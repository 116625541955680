import clsx from 'clsx'
import React, { FC, lazy, useState } from 'react'
import Internet from '../../../../app/consts/IsLoading/Internet'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
// import { SearchIng } from '../../../partials/layout/search/SearchIng'
import { useLayout } from '../../core'
import ClickUpCall from '../../../../app/consts/CickUpCall/ClickUpCall'
import { URL_EndPoints } from '../../../../app/consts/Server/URL_EndPoints'
import { postRequest } from '../../../../app/consts/Server/Requests'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import NotificationDrawer from '../../../../app/consts/NotificationDrawer'
import CallSupervisors from '../../../../app/consts/Tooltip/CallSupervisors'
import { GlobalActionMenu } from '../../../../app/consts/GlobalActionMenu'
import CallWaiting from '../../../../app/consts/SweetAlert/CallWaiting'
import LiveClock from '../../../../app/consts/adminPanelCarselona/liveClock/LiveClock'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'
const SearchIng = lazy(() => import('../../../partials/layout/search/SearchIng'))
const Topbar: FC = () => {
  const { config } = useLayout()
  // click up system 
  const userid: any = JSON.parse(localStorage.getItem("user") || "")
  const [loader, setLoader] = useState(false)
  const [ClickUpValue, setClickUpValue] = useState<any>(null)
  const [ClickUpShow, setClickUpShow] = useState<any>(null)
  const [disableWhatsappButton, setdisableWhatsappButton] = useState(true)
  const [Templateid, setTemplateid] = useState<any>("default")

  const [message, setMessage] = useState("Pls wait call connecting")
  const [Re_Render, setRe_Render] = useState<any>(null)
  const [Hide, setHide] = useState<any>(null)
  const [icons, setIcons] = useState<any>("")

  const [isOpen, setIsOpen] = useState<any>(false)
  const [isSupervisorCallOpen, setIsSupervisorCallOpen] = useState<any>(false)

  // click up syster started 
  const clcikUpCallHandler = async () => {
    setHide(3000)
    setRe_Render(Math.random())

    if (ClickUpValue) {
      setLoader(true)
      setHide(20000)
      setRe_Render(Math.random())
      const CallNow: any = await postRequest(URL_EndPoints(null)?.clickToCallFromAdminToLead, {
        customerphone: ClickUpValue,
        userid: userid,
      }, setLoader)

      if (CallNow?.data?.status == 200) {
        setLoader(false)
        setMessage("Call connected successfully")
        setIcons("success")
      }
      else {
        setLoader(true)
        setMessage("Call not initiated ")
        setLoader(false)
      }
    }
    else {
      toast.success("Number is missing")
    }
  }
  const clcikUpWhatsappHandler = async () => {
    setMessage("Please Wait Message Sending......")
    setHide(20000)
    setRe_Render(Math.random())

    if (ClickUpValue && Templateid !== "default") {
      setLoader(true)

      const CallNow: any = await postRequest(URL_EndPoints(null)?.SendCustomerWhatsAppTemplateFromAdmin, {
        templateid: Templateid,
        customerphone: ClickUpValue,
        userid: userid,
      }, setLoader)
      if (CallNow?.data?.status == 200) {
        setLoader(false)
        setMessage(CallNow?.data?.msg)
        setIcons("success")

      }
      else {
        setMessage(CallNow?.data?.msg)
        setLoader(false)
      }
    }
  }
  const closeBtn = () => {
    setClickUpValue("")
    setClickUpShow(false)
    // setOpenSettinModal(false)
  }



  const isRecievingCall: any = localStorage.getItem("call_active_status")

  const handleCallRecievingToggler = async (event: any) => {
    const response = await postRequest(URL_EndPoints()?.updateAdminCallStatus, {
      id: localStorage.getItem("user"),
      call_active_status: event.target.checked ? 1 : 0
    }, null)

    if (response?.data?.status == 200) {
      toast.success(response?.data?.msg, { position: "top-center" })
    }
    else {
      toast.error(response?.data?.msg, { position: "top-center" })
    }


  }



  return (
    <div className='d-flex align-items-stretch flex-shrink-1 '>


      {/* Recieving Call   */}
      <div className='d-flex flex-column'>
        <div className="form-check form-switch mt-6 me-2" >
          <input className="form-check-input me-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(event) => handleCallRecievingToggler(event)} defaultChecked={isRecievingCall == 1 ? true : false} />
          <label className="form-check-label" >RECIEVING CALL</label>
        </div>
        {/* <LiveClock /> */}

      </div>

      {/* GlobalActionMenu */}
      <div className='mt-4 me-2'  >
        <GlobalActionMenu MenusOptions={{}} />
      </div>

      {/* click up call system here  */}
      <button className='btn btn-sm btn-primary mt-4 btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn' style={{ height: "39px" }} onClick={() => setClickUpShow(!ClickUpShow)}>Direct Call</button>
      <ClickUpCall FilteredData={{
        loader,
        setClickUpValue,
        ClickUpValue,
        handleClose: closeBtn,
        clcikUpCallHandler,
        clcikUpWhatsappHandler,
        setdisableWhatsappButton,
        disableWhatsappButton,
        setTemplateid,
        ClickUpShow,
      }}
        handleCloseForm={closeBtn}></ClickUpCall>
      {
        Hide === 20000 && <CallWaiting Render={Re_Render} Hide={Hide} message={message} icons={icons} setIcons={setIcons} setMessage={setMessage} setRe_Render={setRe_Render} />
      }

      {/*direct call to supervisor  */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

        <button type="button" onClick={() => setIsSupervisorCallOpen(true)} className="btn btn-sm btn-primary  btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn ">Call Supervisor
        </button>

        <CallSupervisors isSupervisorCallOpen={isSupervisorCallOpen} setIsSupervisorCallOpen={setIsSupervisorCallOpen} />

      </div>


      {/* Notifications */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

        <button type="button" onClick={() => setIsOpen(true)} className="btn btn-icon pulse">

          <FontAwesomeIcon icon={faBell} color='#009ef7' size='xl' />
          <span className="pulse-ring"></span>
        </button>

        <NotificationDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      </div>


      {/* Search ===================================================== */}
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <SearchIng />
      </div>

      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
        />
      </div>
      {/* end::Theme mode */}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >

        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >

          <img src={toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' />

        </div>
        <HeaderUserMenu />
      </div>


      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>

      )}


    </div>
  )
}
export { Topbar }
