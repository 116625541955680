import { useEffect, useState } from 'react';
import moment from 'moment';
import OpenChat from '../../consts/champChatDashboard/OpenChat';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { postRequest } from '../../consts/Server/Requests';
import { toast } from 'react-toastify';
import IsLoading from '../../consts/IsLoading/IsLoading';
let arr = ["Pending", "Approved", "Denied"]

export const columns = (handleFetchAllCallLogs?: any, data?: any) => [
    {
        id: 1,
        name: "ACTION",
        cell: (row: any) => <Actions row={row} />,
        minWidth: "200px",
    },
    {
        id: 2,
        reorder: true,
        name: "CHAMP NAME",
        cell: (row: any) => <OpenChat title={row?.cleanerReferBy?.first_name || row?.cleanerReferBy?.last_name ? row?.cleanerReferBy?.first_name + " " + row?.cleanerReferBy?.last_name : "NA"} champId={row?.cleanerReferBy?.id} phone={row?.cleanerReferBy?.phone} />,
        sortField: 'cleanerReferBy.first_name',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 3,
        reorder: true,
        name: "CANDIDATE NAME",
        cell: (row: any) => <OpenChat title={row?.first_name || row?.last_name ? row?.first_name + " " + row?.last_name : "NA"} champId={row?.id} phone={row?.phone} trackData={data} />,
        sortField: 'first_name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 4,
        reorder: true,
        name: "CANDIDATE STATUS",
        cell: (row: any) => row?.cleanerMasterOperationReasons?.name ?? "Null",
        sortField: 'cleanerMasterOperationReasons.name',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 12,
        reorder: true,
        name: "CHAMP STATUS",
        cell: (row: any) => row?.champ_status ? row?.champ_status : "Null",
        sortField: 'champ_status',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 5,
        reorder: true,
        name: "LEAD CREATION DATE",
        cell: (row: any) => moment(row?.createdAt).format("YYYY-MM-DD") ?? "Null",
        sortField: 'createdAt',
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 6,
        reorder: true,
        name: "ACTIVATION DATE",
        cell: (row: any) => row?.champHistory?.date ?? "Null",
        sortField: 'champHistory.date',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 7,
        reorder: true,
        name: "NO. OF DAYS PASSED",
        cell: (row: any) => row?.numOfDaysPassed ?? "Null",
        // sortField: 'numOfDaysPassed',
        // sortable: true,
        minWidth: "200px",
    },

    {
        id: 8,
        reorder: true,
        name: "PAYMENT AMOUNT",
        cell: (row: any) => row?.champReferralRequest?.amount ?? "Null",
        // cell: (row: any) => <AmountEditable row={row} />,
        sortField: 'champReferralRequest.amount',
        sortable: true,
        minWidth: "200px",
    },

    {
        id: 9,
        reorder: true,
        name: "PAYMENT STATUS",
        cell: (row: any) => row?.champReferralRequest?.status ?? "Null",
        sortField: 'champReferralRequest.status',
        sortable: true,
        minWidth: "200px",
    },

];

const Actions = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState<any>(row)

    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const [pending, setPending] = useState<any>(false)
    const hnadleUpdate = async (value: any) => {
        setPending(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateRequestForReward, {
            "id": row?.champReferralRequest?.id,
            "status": value?.toLowerCase()
        }, setPending)
        toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
        if (response?.data?.status === 200) {
            row.champReferralRequest.status = value?.toLowerCase()
            setStoreRow(row)
        }

    }

    return (
        <>
            {
                pending ? <IsLoading /> : <select
                    name='date_typer'
                    data-control='select2'
                    data-placeholder='Period'
                    className='form-select'
                    defaultValue={storeRow?.champReferralRequest?.status}
                    onChange={(event: any) => hnadleUpdate(event.target.value)}
                    disabled={row?.champReferralRequest == null}
                >
                    {
                        row?.champReferralRequest == null && < option  > No Referral Request </option>
                    }
                    {
                        arr?.map((ele: any) => {
                            return (
                                <>
                                    < option value={ele?.toLowerCase()} >{ele}</option>
                                </>
                            )
                        })
                    }
                </select>
            }
        </>
    )
}
