import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import FCMNotification from '../FCMNotification';
import { LocalBaseURL } from '../BaseURLmanagement';
import "./appGlobal.css"
import OpenChat from './consts/champChatDashboard/OpenChat';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from './consts/Server/Requests';
import { URL_EndPoints } from './consts/Server/URL_EndPoints';

const App = () => {
  const showChamp = useSelector((store: any) => store.champsReducer.showChamp)
  const dispatch = useDispatch()
  const getUserAllowedPermissions = async () => {
    const result = await getRequest(URL_EndPoints()?.getUserAllowedPermissions, null)
    dispatch({ type: "STORE_USER_ALLOWED_PERMISSION_LIST", payload: result?.data?.data })
  }

  useEffect(() => {
    getUserAllowedPermissions()
  }, [])
  LocalBaseURL()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer />
      <FCMNotification />
      {/* open champ dashboard from outside  */}

      {
        showChamp?.show && showChamp?.champId ? < OpenChat
          title={""}
          phone={""}
          champId={showChamp?.champId}
          trackData={[]}
          showChamp={showChamp?.show}
          notificationTicketId={showChamp?.ticketid}
        /> : <></>
      }
    </Suspense>
  )
}
export { App }
