import { memo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Bars } from 'react-loader-spinner'

function AudioPalyerModel({ ParentData, Components, audio, setAudio }: any) {
  window.onclick = (event: any) => {
    if (event?.target?.id !== "plays") {
      setAudio("")
    }
  }
  return (
    <Dropdown>
      <Dropdown.Toggle variant="white" className='bg-red  text-white'>

        {
          ParentData?.element?.RecordingUrl == audio && ParentData?.palaying ||ParentData?.palaying && audio ?
            <Bars
              height="20"
              width="20"
              color="#0000FF"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> :
            <i className="bi bi-play-circle fs-1 ms-1 text-dark text-hover-primary cursor-pointer " id="plays"  ></i>
        }
      </Dropdown.Toggle >
      <Dropdown.Menu>
        <Components ParentData={ParentData} newAudio={audio}></Components>
      </Dropdown.Menu>
    </Dropdown >
  );
}
export default memo(AudioPalyerModel);