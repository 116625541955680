import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { URL_EndPoints } from "../../Server/URL_EndPoints";
import { postRequest } from "../../Server/Requests";
import { ContainerCss } from "../../reuseModules/ReactTableComponent/FormStyleCssSheet";
const DueDateUpdateForm = ({ FilteredData, handleCloseForm, size, row }: any) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)

    const [dueDate, setDueDate] = React.useState<any>(FilteredData?.prev_due_date ? FilteredData?.prev_due_date : moment().add(0, "days").format("YYYY-MM-DD HH:mm:ss"));


    const handleDueDateSaveAction = async () => {
        if (dueDate) {
            setLoader(true)
            FilteredData.due_date = dueDate
            const response = await postRequest(URL_EndPoints()?.updateDueDate, FilteredData, setLoader)
            if (response?.data?.status == 200) {
                toast.success(response?.data?.msg)
                setDisable(200)
                row.defaultDueDate = dueDate
                dispatch({
                    type: "UPDATE_CHAT_DEFAULT_VALUE_DUE_DATE", payload: {
                        random: Math.random(),
                        source: "pauseUnpaid",
                        source_due_date: "dueDatePuaseUnPaid",
                        defaultDueDate: dueDate ? dueDate : 'Not Set',
                        subscriptionid: row?.id,
                    }
                })
                handleCloseForm()
            }
            else{
                toast.success(response?.data?.msg)


            }
        } else {
            toast.error("Date is missing")
            handleCloseForm()
        }
    }



    return (
        <div className="p-4" style={ContainerCss}>
            <div style={{}} className="d-flex justify-content-between  pt-2">
                <h5 className="ms-4">Due Date </h5>
                <i className="bi bi-x fs-2 cursor-pointer" onClick={handleCloseForm}></i>
            </div>
            <div className="p-2 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-2 ">
                        <div className={`col-${size || 12}  mb-`}>
                            <input
                                placeholder='Enter Rating..'
                                name='rating'
                                type='datetime-local'
                                value={moment(new Date(dueDate)).format('YYYY-MM-DD HH:mm:ss') || moment().add(0, "days").format("YYYY-MM-DD HH:mm:ss")}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => setDueDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between ms-4 mt-2'>
                    <div className='d-flex  align-items-center  '>
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={handleDueDateSaveAction}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}





export default DueDateUpdateForm;
