import moment from "moment";
import GChatBTN from "../../consts/Chats/ChatButtons/GChatBTN";
import { useState } from "react";
import AdminYesNoAlertAction from "../admins/adminComponents/AdminYesNoAlertAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export const CustomerJobGeneratedColumns = (categoryListData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        selector: (row: any) => row?.id ?? "NA",
        sortable: true,
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "NAME",
        cell: (row: any) => {
            return <GChatBTN
                ticketDataParent={{
                    id: null,
                    customerid: row?.customerid
                }}
                Title={row?.name}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
            // icons={"commenting"}
            // from="seacching"

            ></GChatBTN>

        },
        sortable: true,
        width: "200px",
        sortFunction: (row1: any, row2: any) => {
            const field1: any = row1?.name;
            const field2: any = row2?.name;
            if (field1 > field2) {
                return 1;
            }
            if (field2 > field1) {
                return -1;
            }
            else {
                return 0;
            }
        },

    },
    {
        id: 10,
        reorder: true,
        name: "REGENERATE JOB",
        cell: (row: any) => <RegenerateJob row={row} />,
        // sortable: true,
        width: "120px"

    },
    {
        id: 3,
        reorder: true,
        name: "PAYMENT DATE",
        selector: (row: any) => row?.paymentdate ? moment(row?.paymentdate).format("DD-MM-YYYY") : "NA",
        sortable: true,
        width: "120px"

    },
 
    {
        id: 4,
        reorder: true,
        name: "START DATE",
        selector: (row: any) => row?.startdate ? moment(row?.startdate).format("DD-MM-YYYY") : "NA",
        sortable: true,
        width: "120px"

    },
    {
        id: 5,
        reorder: true,
        name: "END DATE",
        selector: (row: any) => row?.enddate ? moment(row?.enddate).format("DD-MM-YYYY") : "NA",
        sortable: true,
        width: "120px"

    },
    {
        id: 6,
        reorder: true,
        name: "TIMESLOT",
        selector: (row: any) => row?.timeslotData?.name ? row?.timeslotData?.name : "NA",
        sortable: true,
        width: "100px"
    },
    {
        id: 7,
        reorder: true,
        name: "CX TIMESLOT",
        selector: (row: any) => row?.timeslotCustomerSelectedDetail?.name ? row?.timeslotCustomerSelectedDetail?.name : "NA",
        sortable: true,
        width: "110px"

    },
    {
        id: 8,
        reorder: true,
        name: "VEHICLE NO.",
        selector: (row: any) => row?.vehicle?.vehicleno ? row?.vehicle?.vehicleno : "NA",
        sortable: true,
        width: "120px"

    },
    {
        id: 9,
        reorder: true,
        name: "FREQUENCY",
        selector: (row: any) => row?.masterFrequency?.name ? row?.masterFrequency?.name : "NA",
        sortable: true,
    },
    {
        id: 10,
        reorder: true,
        name: "PHONE",
        selector: (row: any) => row?.phone ? row?.phone : "NA",
        sortable: true,
    },

]


const RegenerateJob = ({ row }: any) => {
    const [regenerateJob, setRegenerateJob] = useState<any>(null)
    const [updatedValue, setUpdateValue] = useState<any>(null)
    const setUpdatedIsBlock = (payload: any) => {
        setUpdateValue("Regenerated Job ✅ ")
    }

    return (
        <>
            {
                updatedValue ? updatedValue : <FontAwesomeIcon icon={faEdit} className="text-muted fs-5" onClick={()=>setRegenerateJob(Math.random())} />

            }            {
                regenerateJob ? <div key={row?.id + "_" + row?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to regenerate job ?`}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            subscriptionid: row?.id,
                        }}
                        show={regenerateJob}
                        setShow={setRegenerateJob}
                        setUpdatePrevState={setUpdatedIsBlock}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="generateAttendance"
                        row={row}
                    />
                </div> : <></>
            }


        </>
    )
}

export default RegenerateJob