import React, { useState, useEffect, useCallback, memo } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { LocalBaseURL } from '../../../../BaseURLmanagement'
import { AllFielSearch } from '../../FieldSearch/AllFieldSearch'
import IsLoading from '../../IsLoading/IsLoading'
import Customer_Info from '../Components/Customer_Info'
import SubcriptionsDataList from '../Components/SubcriptionsDataList'
import AddressesList from '../Components/AddressesList'
import QuickReply from '../Components/QuickReply'
import Old_JobList from '../../../modules/Old_JobList/Old_JobList'
import { useSelector } from 'react-redux'
import VehicleInfo from '../Components/VehicleInfo'
import MomentDate from '../../MomentDate'
import ChatHistoryListNew from './ChatHistoryListNew'
import { CallogsBody } from '../Components/CallogsBody'
import clsx from 'clsx'
import OfferLists from '../Components/OfferLists'
import "../custom.css"
import TransactionsHistory from '../Components/TransactionsHistory'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../Server/Requests'
import moment from 'moment'
import { ColorRing } from 'react-loader-spinner'
import CreditCoinsForm from '../Components/Forms/CreditCoinsForm'
import { ChatActions } from '../ChatActions'
import { toast } from 'react-toastify'
import copy from "copy-to-clipboard";
import ToolTips from '../../Tooltip/ToolTips'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Gallary from '../Components/Gallary'
import IsWhatsAppEnabled from '../../components/IsWhatsAppEnabled'
import { isAllowedChampNumber, isAllowedCopyNumber } from '../../components/Utility'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faReply, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { adminToastRunner, useAdminManagementPermission } from '../../../modules/admins/adminUtils'
import LoaderSpinner from '../../../modules/admins/LoaderSpinner'
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant'

const ChatDashboardBody = ({
  TicketListDatas,
  customerDetailData,
  loading,
  quickResponseData,
  subscriptionHistory,
  activeSubscrptions,
  vehicleData,
  coinHistory,
  offersData,
  supervisorData,
  TransactionListData,
  walletTransactions,
  jobRating,
  ticketDataRedux,
  currenOpenedTicketData
}: any) => {
  const { permissionWrapperContextUtil } = useAdminManagementPermission()

  const [RowData, setRowData] = useState<any>(customerDetailData)
  useEffect(() => { setRowData(customerDetailData) }, [customerDetailData])

  const JobListDate = useSelector((store: any) => store.ChatReducers.JobListDate)
  const JobListSubscriptionid = useSelector(
    (store: any) => store.ChatReducers.JobListSubscriptionid
  )
  const JobListRender = useSelector((store: any) => store.ChatReducers.JobListRender)
  const JobListEventKey = useSelector((store: any) => store.ChatReducers.JobListEventKey ?? 2)
  LocalBaseURL()

  const [quickResponseDatas, setquickResponseDatas] = useState<any>([])
  const [quickResponseDataFilter, setquickResponseDataFilter] = useState<any>(quickResponseData)
  const [render, setRender] = useState<boolean>(false)
  const [key, setKey] = useState<number>(2)
  const [subscriptionid, setSubscriptionid] = useState<any>(null)
  const [CreditCoinsModel, setCreditCoinsModel] = useState<any>(false)
  const [generating, setGenerating] = useState<any>(false)
  const [allwalletTransactions, setAllwalletTransactions] = useState<any>([])
  const [search, SetSearch] = useState('')
  const [loader, setLoader] = useState<any>(true)
  const [filterData, setfilterData] = useState<any>({ gallary: [] })

  const loadGallary = async () => {
    setLoader(true)
    const Gallary = await postRequest(URL_EndPoints()?.getAllImagesByCustomerId, { customerId: customerDetailData?.id }, setLoader)
    setfilterData({ ...filterData, ["gallary"]: Gallary?.data?.data })
    setLoader(false)
  }


  React.useEffect(() => {
    customerDetailData?.id && key == 14 ? loadGallary() : <></>
  }, [customerDetailData?.id, key])


  const handleSelect = (key: any) => {
    key == 3 ? setRender(true) : setRender(false)
    setKey(key)
  }

  React.useEffect(() => {
    setAllwalletTransactions(walletTransactions)
  }, [walletTransactions])


  React.useEffect(() => {
    setquickResponseDatas(quickResponseData)
    setquickResponseDataFilter(quickResponseData)
  }, [quickResponseData, key])


  React.useEffect(() => {
    setKey(JobListEventKey)
    setSubscriptionid(JobListSubscriptionid)
  }, [JobListRender])

  React.useEffect(() => {
    const updatedData = AllFielSearch(quickResponseDatas, search)
    setquickResponseDataFilter(updatedData)
  }, [search])

  const toggleHandler = () => {
  }

  async function generateWalletTransaction() {
    setGenerating(true)
    const walletTransactions = await postRequest(URL_EndPoints()?.generateWalletTransaction, { customerid: customerDetailData?.id }, null)
    if (walletTransactions.status == 200) {
      setAllwalletTransactions(walletTransactions.data.data);
    }
    setGenerating(false)
  }

  async function getWalletTransaction() {
    setGenerating(true)
    const walletTransactions = await postRequest(URL_EndPoints()?.getWalletTransactions, { customerid: customerDetailData?.id }, null)
    if (walletTransactions.status == 200) {
      setAllwalletTransactions(walletTransactions.data.data);
    }
    setGenerating(false)
  }

  async function Creditcoins() {
    setCreditCoinsModel(true)
  }

  const handleClose = () => {
    setCreditCoinsModel(false)
  }

  const handleViewDeduction = (deductions: any) => {
    var table = document.querySelector('.walletTableDiv');
    if (table) {
      let line: any = deductions[0].for_id
      var rows = table.querySelectorAll('tr');
      rows.forEach(row => row.classList.remove('active'))
      for (let i = 0; i < deductions.length; i++) {
        const deduction = deductions[i];
        rows[deduction.for_id].classList.add('active');
      }
      // window.scroll({ top: rows[line]['offsetTop'], behavior: 'smooth' });
      // document.querySelector('.walletTableDiv')?.scrollTo({
      //   top: rows[line].clientHeight * 50,
      //   behavior: 'smooth'
      // });
      // rows[line].scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'center'
      // });
      // rows[line].parentNode.scrollTop = rows[line].offsetTop;
      // window.scroll({ top: rows[line].offsetTop, behavior: 'smooth' });
      // if(rows[line]?.parentNode?.parentNode){
      //   rows[line].parentNode.scrollTop = rows[line]?.offsetTop - rows[line]?.parentNode?.parentNode?.offsetTop;
      // }
    }
  }



  function callback(value: any) {
    copy(value)
    if (isAllowedCopyNumber()) {
      toast.success(`Copied to clipboard - ( ${value} ) `, { position: "top-center" })
    }
  }

  const copyToClipboards = React.useCallback(callback, [callback])

  const handleExport = () => {
    const doc: any = new jsPDF()
    doc.autoTable({ html: '#walletTransaction' })
    doc.save('wallet_transactions.pdf')

  }
  const [ipBlockLoader, setIpBlockLoader] = useState(false)

  const ipBlockHander = async () => {
    setIpBlockLoader(true)
    const response: any = await postRequest(URL_EndPoints(null)?.blockUnblockCustomerLogin, {
      customerid: customerDetailData?.id,
    }, setIpBlockLoader)
    adminToastRunner(response, "message")
    if (response?.data?.status === 200) {
      customerDetailData.is_blocked = customerDetailData.is_blocked == 1 ? 0 : 1
      setRowData(customerDetailData)
    }
  }
  let isIpBlocked = RowData?.is_blocked == 1 ? true : false
  return (
    // <div>
    <div className='card p-1 customcss'>
      <div className='d-flex flex-wrap align-items-start bg  rounded  '>

        <span className='mr-1 ml-4 cursor-pointer me-2' onClick={() => copyToClipboards(customerDetailData?.first_name)}>
          Name : {customerDetailData?.first_name + " " + customerDetailData?.last_name}
        </span>

        <span id='phone' className='mr-1 ml-4 cursor-pointer me-2' onClick={() => copyToClipboards(customerDetailData?.phone)}>
          <span className='me-1' > Phone: {isAllowedChampNumber(customerDetailData?.phone, "🚫🚫🚫")}</span>
          <ToolTips toolTipId="phone" ToolTipShowData="Click to copy Number" />

        </span>


        <span id='coins' className='mr-1 ml-4 cursor-pointer me-2' onClick={() => copyToClipboards(customerDetailData?.coins)}>
          <span className='me-1'> Coins:<span className=''> {customerDetailData?.coins}</span> </span>
          <ToolTips toolTipId="coins" ToolTipShowData="Click to copy Coins" />

        </span>

        <span id='coinsvalue' className='mr-1 ml-4 cursor-pointer me-2' onClick={() => copyToClipboards(customerDetailData?.coins_value)}>
          <span className='me-1'> Coins Value: <span className=''> {customerDetailData?.coins_value} </span></span>
          <ToolTips toolTipId="coinsvalue" ToolTipShowData="Click to copy Coins Value" />
        </span>


        <span className='mr-1 ml-4 cursor-pointer me-2' >
          <span className='me-1'> IsWati Enable: <span className='ms-1'>
            <IsWhatsAppEnabled
              row={null}
              Ids={customerDetailData?.id}
              templateType={1}
              keyName="ticketIds"
              ApiEndUrl="sendBulkWhatsAppTemplate"
              to="Customer"
              IsRequired={true}
            />
          </span></span>
        </span>



        <span className='mr-1 ml-4 cursor-pointer me-2' >
          <span className='me-1'> IP Blocked: <span className={`ms-1 badge badge-light-${isIpBlocked ? "danger" : "success"} text-dark me-2`}>
            {isIpBlocked ? "Login Block" : "Not Block"}
            {
              ipBlockLoader ? <LoaderSpinner /> : <FontAwesomeIcon icon={faUndoAlt} className='ms-2 text-muted cursor-pointer' onClick={() => ipBlockHander()} />
            }
          </span></span>
        </span>


      </div>

      <hr style={{ paddingBottom: "0px", marginTop: "0px", paddingTop: "0px", color: "#9f9f9f" }} />

      {
        <>
          <div className='call-button' style={{ position: "absolute", zIndex: 999, right: "1.1%", top: "4%", borderRadius: "50px", }} >
            <ChatActions customerDetailData={customerDetailData} />
          </div>
          <Tabs activeKey={key} onSelect={handleSelect} id='controlled-tab-example'>

            {
              permissionWrapperContextUtil("cx-management-dashboard-customer-information") && <Tab eventKey={1} title='Customer Info'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <Customer_Info customerDetailData={customerDetailData} supervisorData={supervisorData} jobRating={jobRating}></Customer_Info>
                )}
              </Tab>
            }

            {
              permissionWrapperContextUtil("cx-management-dashboard-chat-history") && <Tab eventKey={2} title='Chat History'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <ChatHistoryListNew
                    TicketListDatas={TicketListDatas}
                    customerDetailData={customerDetailData}
                    ticketDataRedux={ticketDataRedux}
                    currenOpenedTicketData={currenOpenedTicketData}
                  ></ChatHistoryListNew>
                )}
              </Tab>
            }
            {
              permissionWrapperContextUtil("cx-management-dashboard-call-logs") && <Tab eventKey={3} title='Call Logs'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <CallogsBody keyName={key} customerDetailData={customerDetailData}></CallogsBody>
                )}
              </Tab>
            }

            {
              permissionWrapperContextUtil("cx-management-dashboard-active-subscription") &&
              <Tab eventKey={4} title='Active Subscriptions'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <div style={{ width: "100%", position: "absolute", overflow: "scroll", height: "85%" }} >

                    <SubcriptionsDataList
                      random="active"
                      eventChenged={key}
                      SubcriptionsDataList={activeSubscrptions}
                      SubcriptionsDataLists={vehicleData}
                      ctAddresses={customerDetailData?.ctAddresses}
                    ></SubcriptionsDataList>

                  </div>

                )}
              </Tab>
            }
            {
              permissionWrapperContextUtil("cx-management-dashboard-subscription-history") &&
              <Tab eventKey={10} title='Subscriptions History'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <div style={{ width: "100%", position: "absolute", overflow: "scroll", height: "85%" }} >

                    <SubcriptionsDataList
                      random="history"
                      eventChenged={key}
                      SubcriptionsDataList={subscriptionHistory}
                      SubcriptionsDataLists={vehicleData}
                      ctAddresses={customerDetailData?.ctAddresses}
                    ></SubcriptionsDataList>

                  </div>
                )}
              </Tab>
            }
            {
              permissionWrapperContextUtil("cx-management-dashboard-addresses") &&
              <Tab eventKey={5} title='Addresses'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <AddressesList ctAddresses={customerDetailData?.ctAddresses}></AddressesList>
                )}
              </Tab>
            }

            {
              permissionWrapperContextUtil("cx-management-dashboard-vehicles") &&

              <Tab eventKey={6} title='Vehicles'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <VehicleInfo VehicleInfo={vehicleData} />
                )}
              </Tab>
            }


            {/* <Tab eventKey={7} title='Coin History'>
              {loading ? (
                <IsLoading></IsLoading>
              ) : (
                <div className=' customer-detail-bodys' >
                  <div
                    // style={{ maxHeight: coinHistory?.length !== 0 ? "550px" : "" }}
                    className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                  >
                    <div className='px-3 mt-4 margins'>
                      <table className="table table-bordered">
                        <thead>
                          <tr className='border px-3 bg-secondary'>
                            <th scope="col">#</th>
                            <th scope="col">DATE</th>
                            <th scope="col">POINTS</th>
                            <th scope="col">FOR</th>
                            <th scope="col">STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coinHistory?.map((element: any) => {
                            return (
                              <tr className={element?.status == "credit" ? "border" : "border text-white bg-red"} >
                                <td scope="row">{element?.id ? element?.id : "NA"}</td>
                                <td >{element?.date ? moment(element.date).format("YYYY-MM-DD") : "NA"}</td>
                                <td >{element?.points ? element?.points : "NA"}</td>
                                <td > {element?.for ? element?.for : "NA"} </td>
                                <td >{element?.status ? element?.status : "NA"}</td>
                              </tr>
                            )
                          }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Tab> */}

            {
              // permissionWrapperContextUtil("cx-management-dashboard-new-coin-history") &&
              <Tab eventKey={13} title='New Coin History'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <div className=' customer-detail-bodys' >
                    {/* id={'kt_chat_messenger_body'}
                      id={'kt_chat_messenger_body'} */}
                    <div
                      // style={{ maxHeight: coinHistory?.length !== 0 ? "550px" : "" }}
                      className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                      data-kt-element='messages'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                      data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
                    >
                      <div className='px-3 pb-2 mt-4 margins walletTableDiv'>
                        <div className='d-flex justify-content-between'>
                          <div>
                            {/* <Button className='mr-1 ml-4' variant="primary" onClick={() => Creditcoins()}>Credit Coin</Button> */}

                            {/* <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-c"]}> */}
                              {
                                allwalletTransactions.length == 0 ?
                                  <Button variant="primary" onClick={generateWalletTransaction}>Generate Coins Transactions</Button>
                                  : <div style={{ cursor: "pointer" }} onClick={() => handleExport()}>
                                    <i className="fa-solid fa-file-export"></i> Export
                                  </div>
                              }
                            {/* </PermissionContextProvider> */}




                          </div>
                          <div>
                            <div style={{ cursor: "pointer" }} onClick={() => getWalletTransaction()}>
                              <i className="fa-solid fa-rotate-right"></i> refresh
                            </div>
                          </div>
                        </div>

                        {generating ?
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                          /> :
                          <table className="table table-bordered new_wallet" id='walletTransaction'>
                            <thead>
                              <tr className='border px-3 bg-secondary'>
                                {/* <th scope="col">ID</th> */}
                                {/* <th scope="col">SUBSCRIPTION ID</th> */}
                                <th scope="col">JOB ID</th>
                                <th scope="col">DATE</th>
                                <th scope="col">REASON</th>
                                <th scope="col">COINS</th>
                                <th scope="col">CLOSING BALANCE</th>
                                {/* <th scope="col">REMAINING COINS</th> */}
                                <th scope="col">PER COIN VALUE</th>
                                <th scope="col">TRANSACTION DATE</th>

                                {/* <th scope="col">FOR</th> */}
                                {/* <th scope="col">DEDUCTIONS</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {allwalletTransactions?.map((element: any) => {
                                return (
                                  <tr className={element?.transaction_type == "credit" ? "border credited_row" : "border debited_row"} >
                                    <td >{element?.job_id ? element?.job_id : "NA"}</td>
                                    <td >{element?.job_id > 0 ? moment(element?.attendence?.attendencedate).format('Do MMMM YYYY') : moment.utc(element?.transaction_datetime, 'YYYY-MM-DDTHH:mm:ss').utcOffset(0).format('Do MMMM YYYY')}</td>

                                    <td >{element?.job_id == 0 && element?.transaction_type == "debit" ? element?.subscriptionMonth?.packageDetail?.name : (element?.reason ? element?.reason?.reason : "-")}</td>

                                    <td >{element?.coins ? element?.coins : "NA"}</td>
                                    <td >{element?.closing_balance}</td>
                                    <td >{element?.one_coin_value ? element?.one_coin_value : "NA"}</td>
                                    <td >{moment.utc(element?.transaction_datetime, 'YYYY-MM-DDTHH:mm:ss').utcOffset(0).format('Do MMMM YYYY HH:mm:ss')}</td>

                                  </tr>
                                )
                              }
                              )}
                            </tbody>
                          </table>
                        }
                      </div>
                    </div>
                    {
                      CreditCoinsModel && <CreditCoinsForm PopUpPostFormOpen={setCreditCoinsModel} handleClose={handleClose} From="Create" row={{}} />
                    }
                  </div>
                )}
              </Tab>
            }

            {
              permissionWrapperContextUtil("cx-management-dashboard-job-list") &&
              <Tab eventKey={8} title='Job List' onClick={toggleHandler}  >
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <div style={{ width: "100%", position: "absolute", overflow: "scroll", height: "85%" }} >


                    {
                      key == 8 ? (
                        <Old_JobList
                          subscriptionid={subscriptionid}
                          JobListDate={JobListDate}
                          JobListDateTabclick={{
                            // attendanceDateTo,
                            // attendanceDateFrom
                            attendanceDateTo: MomentDate(7),
                            attendanceDateFrom: MomentDate(-21),
                            reference: "JobList",
                            key: key
                          }}
                          customerid={customerDetailData?.id}
                        />
                      ) : (
                        <div></div>
                      )
                    }
                  </div>
                )}
              </Tab>
            }
            {
              permissionWrapperContextUtil("cx-management-dashboard-offer-list") &&
              <Tab eventKey={11} title='Offer Lists'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) : (
                  <OfferLists offersData={offersData} ></OfferLists>
                )}
              </Tab>
            }

            {
              permissionWrapperContextUtil("cx-management-dashboard-transaction-list") &&
              <Tab eventKey={12} title='Transaction Lists'>
                {loading ? (
                  <IsLoading></IsLoading>
                ) :
                  <TransactionsHistory TransactionListData={TransactionListData} ></TransactionsHistory>
                }
              </Tab>

            }


            {
              permissionWrapperContextUtil("cx-management-dashboard-gallary") &&

              <Tab eventKey={14} title='Gallary'>
                {loader ? (
                  <IsLoading></IsLoading>
                ) : (
                  <Gallary filterData={filterData["gallary"]} ></Gallary>
                )}
              </Tab>
            }


            {
              permissionWrapperContextUtil("cx-management-dashboard-quick-reply") &&
              <Tab eventKey={9} title='Quick Reply'>
                <>
                  <div className='row  ms-3 mt-2'>

                    <input
                      type='text'
                      className='form-control bg-secondary me-2  border'
                      placeholder='Search Here...'
                      style={{ height: "40px", width: "50%" }}
                      onChange={(e) => SetSearch(e.target.value)}
                      value={search}
                    />
                  </div>
                  {loading ? (
                    <IsLoading></IsLoading>
                  ) : (
                    <QuickReply quickResponseDataFilter={quickResponseDataFilter}></QuickReply>
                  )}
                </>
              </Tab>
            }

          </Tabs>
        </>
      }
    </div >
  )
}
export default memo(ChatDashboardBody)
