import React from 'react'
import Not_Found_Data from '../../Not_Found_Data'
import uuid from 'react-uuid';

import { VehicleInfoBody } from './VehicleInfoBody'
const VehicleInfo = ({ VehicleInfo }: any) => {
   
    return (
        <>
          <div className='mb-5 mr-4 px-4 mt-3 p-3' key={uuid()}>
            <div className='container px-1'>
              <div className='row '>
                {VehicleInfo?.length > 0 ? (
                  VehicleInfo?.map((element: any, index:any) => {
                    
                    return (
                        <div className=' col mb-3 me-2 h-100' key={uuid()}>
                          <VehicleInfoBody
                            className='card-xl-stretch mb-xl-8'
                            image='abstract-4.svg'
                            title={element.vehicleBrand?.name}
                            vehicleData={element}
                            description='Create a headline that is informative<br/>and will capture readers'
                          />
                        </div>
                    )
                  })
                ) : (
                  <Not_Found_Data headerTitle={'No vehicleData Found'}></Not_Found_Data>
                )}
              </div>
            </div>
          </div>
        </>
      )
}
export default VehicleInfo