import React, { useState, useEffect, forwardRef, useRef } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import { columns } from './Columns'
import "../../../styles.css"

import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { getPostMultipleApiHandler, getRequest, postRequest } from '../../consts/Server/Requests'
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap'
import { ShowHideColumns } from '../../consts/ShowHideColumns'
import "./custom.css"
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns'
import { useSelector } from 'react-redux'
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility'
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData'
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler'
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents'
import { newTicketListInititalData } from '../../consts/Server/data'
import AddNewTicketsForm from './AddNewTicketsForm/AddNewTicketsForm'
import DialogBoxGlobal from '../../consts/Dialogbox/DialogBoxGlobal'
import TicketAssigneForm from './TicketAssigneForm'
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList'
import AdminForm from '../admins/adminForms/AdminForm'
import { chooseToShowCustomerData, createTicketformData } from './newTicketListUtils'
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../consts/mediaQueryLayout/LeftBox'
import RightBox from '../../consts/mediaQueryLayout/RightBox'
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton'
import AdminBulkAssignUnassignForm from '../admins/adminForms/AdminBulkAssignUnassignForm'
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils'
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../admins/adminConstant'

const NewTicketsList = ({ filterDataOutsource }: any) => {
  const refreshtTickets = useSelector((store: any) => store?.RefreshPage?.refreshtTickets)
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [sortedColumns, setSortedColumns] = useState<any>(columns())
  const [PopUpPostFormOpen, setPopUpPostFormOpen] = useState(false)
  const [AdminListData, setAdminListData] = React.useState<any>([])
  const [SupervisorsListData, setSupervisorsListData] = React.useState<any>([])
  const [TicketCategoryData, setTicketCategoryData] = React.useState<any[]>([])
  const [ticektSubCategoryData, setticektSubCategoryData] = React.useState<any[]>([])
  const [TicketSources, setTicketSources] = React.useState<any[]>([])

  const handleTicketlistPopform = () => {
    setPopUpPostFormOpen(true)
  }

  async function getFetchInitialData() {
    const results = await getPostMultipleApiHandler(newTicketListInititalData, null)
    setSupervisorsListData(results["getSupervisorList"] || [])
    setAdminListData(results["getAlladminlist"] || [])
    setTicketCategoryData(results["getTicketCategories"] || [])
    setticektSubCategoryData(results["getTicketSubCategories"] || [])
    setTicketSources(results["getTicketSources"] || [])
  }

  // useEffect(() => {
  //   getFetchInitialData()
  // }, [])

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  // server side
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");

  // server sidefilterData
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload())


  useEffect(() => {
    if (filterData) {
      const newColumns = columns(null, filterData?.map((ele: any) => ele?.customerid));
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getTicketList, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.TicketList)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection

    setPayload(payload)
    return payload
  }

  // api calling here logic  here +++++++++++++++++++++++++++++++
  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  // handle pagination here 
  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setPerPageSize(newPerPageSize)
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
  };

  // deboucing  

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  useEffect(() => {
    let { isFilter, ...rest } = filterDataOutsource?.ticketListOpenFilterData?.newTicketList || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, filterData: rest }, setPending)
    isFilter && setPayload({ ...payload, ["filterData"]: rest })

  }, [search,
    refreshtTickets,
    filterDataOutsource?.ticketListOpenFilterData?.newTicketList,
    filterDataOutsource?.ticketListOpenFilterData?.newTicketList?.isFilter
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([]);

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  }

  // bulk feature code .. 
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)

  const HandleCloseModel = () => {
    setPopUpPostFormOpen(false)
  }

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")
  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])
  const [showCampaignContact, setShowCampaignContact] = useState(false);

  const refreshNow = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getTicketList")
  }, [])

  return (
    <div className='tickets-table'>
      {
        <DataTable
          title="Ticket List"
          customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(sortedColumns, "ticketListReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "ticketListReorderedItem")}
          progressPending={pending}
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          selectableRows
          onSelectedRowsChange={handleSelectedRowsChange}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={refreshNow} />


                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-create-new-ticket"]}>
                    <button className='btn btn-sm btn-primary me-3 btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn' onClick={(row) => handleTicketlistPopform()} ><i className='fa fa-plus-circle'></i>Create New Ticket  </button>
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-toggle-date-format"]}>
                    <ShowHideColumns columns={columns()} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-show-hide-column"]}>
                    <ToggleColumns columns={[{ name: "CREATED AT", id: "CREATED AT" }, { name: "UPDATED AT", id: "UPDATED AT" }]} from="TICKET" />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-filter"]}>
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      listApiUrl={listApiUrl("getTicketList")}

                    />
                  </PermissionContextProvider>



                </RightBox>
              </MainContainerWrapper>
              {
                filteredSelectedRows.length > 0 ?
                  <BulkUtilButtonWrapper>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-bulk-ticket-assign"]}>
                      <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                    </PermissionContextProvider>
                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-bulk-add-to-contact-to-list"]}>
                      <BulkUtilButton title="Bulk Add Contacts to Campaign List" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                    </PermissionContextProvider>

                  </BulkUtilButtonWrapper> : <></>
              }

            </>

          }
        />
      }


      {
        PopUpPostFormOpen ? <AdminForm
          show={PopUpPostFormOpen}
          setShow={setPopUpPostFormOpen}
          refreshNow={() => { }}
          toastMessage="msg"
          formData={createTicketformData}
          staticFormData={chooseToShowCustomerData || []}
          initialData={{
            title: "",
            customerid: null,
            categoryid: null,
            subcategoryid: [],
            source_id: null,
            visible_id: 0,
            duedate: "",
            admins: [],
            cleaners: [],
            answer: "",
            userid: localStorage.getItem("user")
          }}
          keyName="createTicketFromNewTicketList"
          formTitle={`Create New Ticket`}
          formSubmitApiUrl="createticketByAdmin"
        /> : <></>
      }

      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.customerid)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={null}
          userType={"customer"}
        /> : <></>
      }

      {
        // showAssignPopUp ? <TicketAssigneForm showAssignPopUp={showAssignPopUp} setShowAssignPopUp={setShowAssignPopUp} filteredSelectedRows={filteredSelectedRows} toggledClearRows={toggledClearRows} setToggleClearRows={setToggleClearRows} setFilteredSelectedRows={setFilteredSelectedRows} /> : <></>
      }


      {
        showAssignPopUp ? <AdminBulkAssignUnassignForm
          show={showAssignPopUp}
          setShow={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          initialData={{
            admins: [],
            cleaners: [],
            admins_to_unassign: [],
            cleaners_to_unassign: [],
            dueDate: "",
            should_unassign: false,
          }}
          validationSchema={{
            admins: { required: false, errorMessage: "Admins is Required !" },
            cleaners: { required: false, errorMessage: "Supervisors is Required !" },
            admins_to_unassign: { required: false, errorMessage: "Unassign Admins is Required !" },
            cleaners_to_unassign: { required: false, errorMessage: "Unassign Supervisor is Required !" },
            dueDate: { required: false, errorMessage: "Due Date is Required !" },
            should_unassign: { required: false, errorMessage: "" }
          }}
          keyName="champLeadAssign"
          formTitle={`Assign ${filteredSelectedRows?.length} Tickets to Admins/Supervisors`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignToBulkTickets"
        /> : <></>
      }


    </div>
  )
}
export default NewTicketsList
// just call this to route file
