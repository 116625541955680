/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import HandleCalendarPopUp from '../../../modules/Old_JobList/Components/DropBox/HandleCalendarPopUp'
import VehicleMenuActions from './VehicleMenuActions'
import uuid from 'react-uuid'
import ToolTips from '../../Tooltip/ToolTips'
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant'
type Props = {
  className: string
  image: string
  title: any
  vehicleData: any
  description: any
}
const VehicleInfoBody: React.FC<Props> = ({
  className,
  image,
  title,
  vehicleData,
  description,
}) => {
  let uuids = uuid()
  return (
    <div
      className={` card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '10% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >
      <div className='card-body p-3'>
        <div className=' ' >
          <span
            className={`badge badge-light-${vehicleData?.isdeleted == 1 ? "danger" : "success"} me-2 mb-2 fs-12`}
            style={{ height: "10px", position: "absolute", top: -6, left: 0 }}
          >
            {vehicleData?.isdeleted == 1 ? "VEHICLE IS DELETED" : ""}
          </span>
        </div>
        <div className='d-flex justify-content-between'>
          <a href='#' className='d-flex card-title fw-bold text-dark mb-3 text-hover-primary fs-4' >
            {title ? title : "N.A.B"}
          </a>
          <div className='d-flex'>


            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicles-calendar"]}>
            <span className='mt-3 me-4 '>
              <HandleCalendarPopUp customerid={vehicleData?.customer_id} vehicleid={vehicleData?.id}></HandleCalendarPopUp>
            </span>
            </PermissionContextProvider>
      
            <span className='text-dark text-hover-primary fs-4  cursor-pointer'>
              <VehicleMenuActions subscriptions={vehicleData} vehicleid={vehicleData.id}></VehicleMenuActions>
            </span>
          </div>
        </div>
        <div className='fw-bold d-flex  text-primary mt-2 my-2 '>
          <span
            className={`badge badge-danger me-2 mb-2`}
            id={uuids + 1}
          >
            {vehicleData?.vehicleBrand?.name ? vehicleData?.vehicleBrand?.name : "N.A.B"}
            {!vehicleData?.vehicleBrand?.name && <ToolTips toolTipId={uuids + 1} ToolTipShowData={"NOT AVAILABLE BRAND"} place={""} />}
          </span>
          <span
            id={uuids + 2}
            className={`badge badge-light-success me-2 mb-2`}
          >
            {vehicleData?.model?.name ? vehicleData?.model?.name : "N.A.M"}
            {!vehicleData?.model?.name && <ToolTips toolTipId={uuids + 2} ToolTipShowData={"NOT AVAILABLE MODEL"} place={""} />
            }
          </span>
          <span
            id={uuids + 3}
            className={`badge badge-primary me-2 mb-2`}
          >
            {vehicleData?.vehicleno ? vehicleData?.vehicleno : "N.A.VN"}
            {!vehicleData?.vehicleno && <ToolTips toolTipId={uuids + 3} ToolTipShowData={"NOT AVAILABLE VEHICLE NO."} place={""} />}
          </span>
        </div>
      </div>
    </div>
  )
}
export { VehicleInfoBody }
