import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import RemoveDublicateVehicle from '../../SweetAlert/RemoveDublicateVehicle';
import DeleteVehicle from '../../SweetAlert/DeleteVehicle';
import VehicleUpdateForm from './VehicleUpdateForm';
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant';
function VehicleMenuActions({ subscriptions, vehicleid }: any) {
    const userid: any = JSON.parse(localStorage.getItem("user") || "")

    const [removedublicateVehicle, setremovedublicateVehicle] = React.useState<any>(null)
    const [vehiclesid, setvehicleid] = React.useState<any>("")
    const [VehicleDeleteShowModel, setVehicleDeleteShowModel] = React.useState<any>("")
    const [VehicleDeleteNumberid, setVehicleDeleteNumberid] = React.useState<any>("")
    const [VehcileUpdateShow, setVehcileUpdateShow] = React.useState<any>(false)



    const RemoveSublicateVehicle = (vehicleidNumber: any) => {
        let randoms = Math.random()
        setremovedublicateVehicle(randoms)
        setvehicleid(vehicleidNumber)
    }
    const VehicleDeletHandler = (vehicleidNumber: any) => {
        let randoms = Math.random()
        setVehicleDeleteShowModel(randoms)
        setVehicleDeleteNumberid(vehicleidNumber)
    }
    const VehicleUpdateHandler = (vehicleidNumber: any) => {
        setVehcileUpdateShow(true)
    }
    const handleClose = () => {
        setVehcileUpdateShow(false)
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant='white' id='dropdown-basic'>
                    <i className='bi bi-three-dots fs-2 '></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>


                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicles-remove-dublicate-vehicle"]}>
                        <Dropdown.Item href='#' onClick={() => RemoveSublicateVehicle(vehicleid)}>
                            Remove Dublicate Vehicle
                            <RemoveDublicateVehicle
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: removedublicateVehicle,
                                    payloadData: {
                                        vehicleid: vehiclesid,
                                    },

                                    successTitle: 'Changes Are Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                    reference: "vehicleInfo"
                                }}
                                localKey={true}
                            ></RemoveDublicateVehicle>
                        </Dropdown.Item>
                    </PermissionContextProvider>



                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicles-delete-vehicle"]}>
                        <Dropdown.Item href='#' onClick={() => VehicleDeletHandler(vehicleid)}>
                            Delete Vehicle
                            <DeleteVehicle
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: VehicleDeleteShowModel,
                                    payloadData: {
                                        customerid: subscriptions?.customer_id,
                                        vehicleid: VehicleDeleteNumberid,
                                        userid: userid,

                                    },

                                    successTitle: 'Changes Are Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                }}
                                localKey={true}
                            ></DeleteVehicle>
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["vehicles-update-vehicle"]}>
                        <Dropdown.Item href='#' onClick={() => VehicleUpdateHandler(vehicleid)}>
                            Update Vehicle
                            {/* payloadData: {
                                    customerid: subscriptions?.customer_id,
                                    vehicleid: VehicleDeleteNumberid,
                                    userid: userid,

                                }, */}

                        </Dropdown.Item>
                    </PermissionContextProvider>

                </Dropdown.Menu>
            </Dropdown>



            {/* pop up forms are here to perform custom actions   */}

            {
                VehcileUpdateShow && <VehicleUpdateForm FilteredData={{
                    handleClose: handleClose,
                    VehcileUpdateShow,
                    payloads: {
                        customerid: subscriptions?.customer_id,
                        vehicleid: subscriptions?.id,
                        newVehicleNumber: ""
                    }
                }} handleCloseForm={handleClose}
                ></VehicleUpdateForm>
            }

        </>
    )
}
export default VehicleMenuActions
