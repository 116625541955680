import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DeleteReason from "./DeleteReason";
import { number } from 'prop-types';
export const columns = () => [


    {
        name: "NAME",
        selector: (row: any) => <>{row?.cleaner?.first_name + " " + row?.cleaner?.last_name}</>,
        sortField: 'first_name',
        sortable: true,
        id: 1,
    },
    {
        name: "ACTION",
        cell: (row: any) => <div className='d-flex g-4 flex-wrap'>
            <DeleteUserHash row={row} />
        </div>,
        sortField: 'is_deleted',
        sortable: true,
        id: 2,

    },
    {
        name: "DELETE REASON",
        selector: (row: any) => row?.delete_reason ? row?.delete_reason : "NA",
        sortField: 'delete_reason',
        sortable: true,
        id: 3,

        // width:"auto"
    },
    {
        name: "PHONE",
        selector: (row: any) => row?.cleaner?.phone ? row?.cleaner?.phone : "NA",
        sortField: 'phone',
        sortable: true,
        id: 4,

        // width:"auto"
    },



];

const DeleteUserHash = ({ row }: any) => {
    const [show, setshow] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    useEffect(() => {
        setStoreRow(row)
    }, [row?.is_deleted])

    return (


        <>

            <div className='d-flex g-4 flex-wrap align-items-center' style={{ cursor: storeRow?.is_deleted == 1 ? "not-allowed" : "pointer" }} onClick={() => setshow(true)}>
                <i className={`bi bi-trash ms-3 fs-5 fw-bold text-${storeRow?.is_deleted == 1 ? "muted" : "danger"}  cursor-pointer me-2`} style={{ cursor: storeRow?.is_deleted == 1 ? "not-allowed" : "pointer" }}></i>
            </div >
            {
                show ? <DeleteReason show={show} setshow={setshow} row={row} setStoreRow={setStoreRow} /> : <></>
            }


        </>

    )
}
