import React, { FC, useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { MenuComponent } from '../../_metronic/assets/ts/components';
import AddCleanerLeadForm from './GlobalActionForms/AddCleanerLeadForm';
import AddCustomerLeadForm from './GlobalActionForms/AddCustomerLeadForm';
export const GlobalActionMenu = ({ MenusOptions }: any) => {

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])


	const [cleanerLeadModal, setCleanerLeadModal] = useState(false);
	const [custmerLeadModal, setCustmerLeadModal] = useState(false);

	const cleanerLeadHandler = () => {
		setCleanerLeadModal(true)
	}

	const handleClose = () => {
		setCleanerLeadModal(false)
		setCustmerLeadModal(false)
	};

	const customerLeadHandler = () => {
		setCustmerLeadModal(true)
	}

	return (
		<>
			<button
				className='btn btn-sm btn-success btn-active-success  fs-8 '
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
				style={{ borderRadius: "100%", width: "40px", height: "40px" }}
			>
				<i className="fa fa-ellipsis-v fs-3 " aria-hidden="true"></i>

			</button>
			<div
				className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 me-12 '
				data-kt-menu='true'
				style={{
					width: "500px",
					zIndex: '105',
					position: 'fixed',
					inset: '0px 0px auto auto',
					margin: '0px',
					transform: 'translate(-59px, 440px)',
					
				}}
			>
			
				<div className='menu-item px-3'>
					<a
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={cleanerLeadHandler}
					>
						Add Cleaner Leads
					</a>
				</div>


				<div className='menu-item px-3 fw-bold'>
					<a
						className='menu-link  px-3 fw-bold'
						data-kt-users-table-filter='delete_row'
						onClick={customerLeadHandler}
					>
						Add Customer Leads
					</a>
				</div>


			</div>


			{
				cleanerLeadModal && <AddCleanerLeadForm handleCloseForm={handleClose} show={cleanerLeadModal} row={MenusOptions} prevData={"prevData"} />
			}

			{
				custmerLeadModal && <AddCustomerLeadForm handleCloseForm={handleClose} show={custmerLeadModal} row={MenusOptions} prevData={"prevData"} />
			}


		</>
	)
}
