import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ChampRowDashboardAction = ({ title, children, icon, setShow, classes, iconCss }: any) => {
    return (
        <p className='mb-3'>
            <span className={classes}>{title}: </span>
            {children}
            {
                setShow && <FontAwesomeIcon icon={icon ? icon : faEdit} className={iconCss} onClick={() => setShow && setShow(true)} />
            }
        </p>

    )
}

export default ChampRowDashboardAction
