import axios from "axios"
import Constants from "../Consts"
import { getFilterListAllInOne } from "../../modules/admins/adminUtils"

const tokens = localStorage.getItem("auth-token")
const userId = localStorage.getItem("user")
const webKit_hash_id = localStorage.getItem("webKit_hash_id")
const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
let baseUrl = `${localKeyCheck ? "https://adminapi.carselonadaily.com/api/admin/" : "https://testadminapi.carselonadaily.com/api/admin/"}`

axios.defaults.baseURL = `${localKeyCheck ?
    "https://adminapi.carselonadaily.com/api/admin"
    :
    "https://testadminapi.carselonadaily.com/api/admin"
    // "https://testadminapi.carselonadaily.com/api/admin"
    }`


// axios.defaults.baseURL = "https://testadminapi.carselonadaily.com/api/admin"
// axios.defaults.baseURL = "http://103.61.224.234:4000/api/admin"

axios.defaults.headers.common['authorization'] = `Bearer ${tokens ? tokens + "," + "Useragenthash " + webKit_hash_id : ""}`
axios.defaults.headers.common['userid'] = `${userId}`
axios.defaults.headers.common['usertype'] = `${5}`
axios.defaults.headers.common['admin'] = true
// axios.defaults.headers.common['userAgentHash'] = `${userAgentHash}`
// axios.defaults.headers.common['content-type'] = `multipart/form-data`



axios.interceptors.response.use(
    (response) => {

        if (response?.data?.status === 401 && response?.data?.message === "Unauthorized") {
            localStorage.removeItem(Constants?.user);
            localStorage.removeItem(Constants?.token);
            document.location.reload();
        } else {
            return response;
        }
    }
);

export async function postRequest(EndPointURL: any, payloads: any, setloading: any) {
    const result: any = axios.post(`/${EndPointURL}`, payloads && payloads).then((res) => {
        setloading && setloading(false)
        return res
    }).catch((err) => {
        setloading && setloading(false)
        return err
    });
    return result;
}


export async function postRequestWithFile(EndPointURL: any, payloads: any, setloading: any) {
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const formData = new FormData();

    Object.keys(payloads).forEach((key) => {
        formData.append(key, payloads[key]);
    });

    const result: any = axios.post(`/${EndPointURL}`, formData).then((res) => {
        setloading && setloading(false)
        return res
    }).catch((err) => {
        setloading && setloading(false)
        return err
    })

    return result;
}

export async function getRequest(EndPointURL: any, setloading: any) {

    const result: any = axios.get(`/${EndPointURL}`).then((res) => {
        setloading && setloading(false)
        return res
    }).catch((err) => {
        setloading && setloading(false)
        return err
    })
    return result;

}


export async function getPostMultipleApiHandler(EndPointURLArr: any = [], setloading: any, isTrue?: any, dynamicApiData?:any) {
    if(isTrue){
        EndPointURLArr = getFilterListAllInOne(EndPointURLArr, dynamicApiData as any)
    }

    const makeApiRequest = (singleApi: any, key: any, method: any, isBody: any, isQuery: any, isParams: any) => {
        let FetchBody: any = {
            method: method,
            headers: {
                'Authorization': `Bearer ${tokens ? tokens + "," + "Useragenthash " + webKit_hash_id : ""}`,
                'Userid': `${userId}`,
                'Usertype': `${5}`,
                'Content-Type': 'application/json'
            }
        }

        let url: any = new URL(singleApi);

        if (isBody) {
            FetchBody.body = JSON.stringify(isBody)
        }

        if (isQuery) {
            let query = isQuery?.split("=")
            url.searchParams.append(query?.[0], query?.[1]);

        }

        if (isParams) {
            Object.keys(isParams).forEach((key) => {
                url = new URL(`${url}/${isParams[key]}`);
            });
        }

        return new Promise((resolve, reject) => {
            fetch(url.toString(), FetchBody)
                .then((res) => res.json())
                .then((res) => {
                    return resolve({ [key]: res })
                })
                .catch((error: any) => {
                    return reject(error)
                })
        })

    }

    const response = await Promise.all(EndPointURLArr?.map((singleApi: any) => makeApiRequest(
        (baseUrl + singleApi?.url),
        (singleApi?.url),
        (singleApi?.method),
        (singleApi?.isBody),
        (singleApi?.isQuery),
        (singleApi?.isParams),
    )))
        .then((response: any) => {
            setloading && setloading(false)
            return response
        })
        .catch((error) => {
            setloading && setloading(false)
            console.error('Error during API requests:', error)
        })

    let finalTaransformResponse: any = {}

    if (response) {
        response?.forEach((ele: any) => {
            let key = Object.keys(ele)[0]
            if (key) {
                finalTaransformResponse[key] = ele[key]?.data || []
            }
        })

    }

    return finalTaransformResponse
}

export const isTestOrAdminApi: any = `${localKeyCheck ? "https://adminapi.carselonadaily.com" : "https://testadminapi.carselonadaily.com"}`
