import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Bars } from 'react-loader-spinner'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
const VehicleUpdateForm = ({ FilteredData }: any) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [VehicleNumber, setVehicleNumber] = useState("")
    const UpdateVehicleHandler = async () => {
        if (FilteredData?.payloads?.customerid) {
            FilteredData.payloads.newVehicleNumber = VehicleNumber
            setLoader(true)
            const UpdateVehicleResonse: any = await postRequest(URL_EndPoints(null)?.vehicleNumberUpdate, FilteredData?.payloads, setLoader)
            if (UpdateVehicleResonse?.data?.status === 200) {
                toast.success(UpdateVehicleResonse?.data?.msg)
                setLoader(false)
                setDisable(null)
                dispatch({
                    type: "VEHICLE_UPDATED", payload: {
                        vehcileid: 1,
                        updateVehicle_render: Math.random()
                    }
                })
                FilteredData?.handleClose()
            }
            else {
                toast.error(UpdateVehicleResonse?.data?.msg)
            }

        }
    }

    return (
        <>
            {FilteredData?.VehcileUpdateShow ? <Modal show={FilteredData?.VehcileUpdateShow} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Vehicle </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>
                        <input
                            type='text'
                            className='form-control  me-2  border'
                            placeholder='Enter Vehicle Number Here...'
                            style={{ height: "50px", width: "100%" }}
                            onChange={(e) => setVehicleNumber(e.target.value)}
                            value={VehicleNumber}
                        />
                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={UpdateVehicleHandler} disabled={disable || loader ? true : false}>
                            {loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Update Vehicle'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default VehicleUpdateForm
