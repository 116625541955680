import React, { useState, useEffect } from 'react'
import { requestForToken, onMessageListener } from './firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationDrawer from './app/consts/NotificationDrawer'
import { ChatTicketID } from './Redux/Action/Chats/ChatAction';
import { useDispatch } from 'react-redux';
import { setIsChatOpenedOrNot } from './app/modules/admins/adminUtils';

const FCMNotification = () => {
    const dispatch = useDispatch()
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isOpen, setIsOpen] = useState(false)
    const openCustomerChat = (notificationData) => {
        if (notificationData?.ticket_type == "champ") {
            setIsChatOpenedOrNot(+notificationData?.ticketid, false, "champ_chat_opened")
            dispatch({ type: "SHOW_CHAMP_DASHBOARD_FROM_OUTSIDE", payload: { champId: +notificationData?.champID, show: true, ticketid:+notificationData?.ticketid } })
        }
        else {
            setIsChatOpenedOrNot(notificationData?.ticketid, false, "customer_chat_opened")
            dispatch(ChatTicketID({
                id: notificationData?.ticketid,
                customerid: notificationData?.customerid,
                random: Math.random(),
            }))
        }
    }

    const notify = () => toast(<ToastDisplay />, { position: toast.POSITION.TOP_CENTER, className: 'fcm_toaster' });
    function ToastDisplay() {
        return (
            <div onClick={() => openCustomerChat(notification?.data)} id={notification?.data?.ticket_type == "champ" ? "" : "kt_drawer_chat2_toggle2"}>
                <p style={{ marginBottom: '0px' }}><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>

            // <div onClick={() => setIsOpen(true)}>
            //     <p style={{ marginBottom: '0px' }}><b>{notification?.title}</b></p>
            //     <p>{notification?.body}</p>
            // </div>
        );
    };

    useEffect(() => {

        if (notification?.title) {
            let hashIds = JSON.parse(localStorage.getItem("notification_ids") || '{}') || {}
            const chat_id = document.querySelector("[data-opened-chat-id")
            let finalChatId = null;
            if (chat_id) {
                const newChatId = chat_id.getAttribute("data-opened-chat-id");
                finalChatId = newChatId
                if (newChatId == hashIds?.id) {
                    dispatch({
                        type: "NOTIFICATION_DATA", payload: {
                            chatName: hashIds?.for,
                            id: hashIds?.id,
                            data: notification?.data?.ticket_type == "customer" ? {
                                "id": 3546256,
                                "ticketid": notification?.ticketid,
                                "answers": notification?.body,
                                "image": "",
                                "userid": 0,
                                "usertype": 0,
                                "wa_number_id": 0,
                                "wa_avatarUrl": "",
                                "wa_conversationId": "",
                                "wa_eventType": "",
                                "wa_id": "",
                                "wa_localMessageId": "",
                                "wa_operatorEmail": "",
                                "wa_operatorName": "",
                                "wa_replyContextId": "",
                                "wa_statusString": "",
                                "wa_templateId": "",
                                "wa_templateName": "",
                                "wa_ticketId": "",
                                "wa_type": "",
                                "whatsappMessageId": "",
                                "status": 1,
                                "customerid": 0,
                                "champ_id": 0,
                                "type": 1,
                                "createdAt": "Recent",
                                "updatedAt": 1725349961000,
                                "name": "Live"
                            } : notification?.data


                        }
                    })
                }
            }

            if (finalChatId != hashIds?.id) {
                notify()
            }
        }

    }, [notification])

    useEffect(() => {
        requestForToken();

    }, [])


    onMessageListener()
        .then((payload) => {
            console.log('payload', payload);
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body, data: payload?.data });
        })
        .catch((err) => console.log('failed: ', err));

    return (
        <>
            <NotificationDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default FCMNotification