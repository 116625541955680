
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import Handsontable from 'handsontable';
import { colWidthsHotTable, handleAfterSelectionRow, hotInstanceLoader, rocordTotalFound, searchFieldKeyupCallback, cellRenderInner, getFilteredRowDataNew2 } from '../admins/adminUtils';
import moment from 'moment';
import MomentDate from '../../consts/MomentDate';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import ShowTicketCountChartModal from '../ticketCountLists/ShowTicketCountChartModal';
import ShowFilterHint from '../ticketCountLists/ticketComponents/ShowFilterHint';
import PaymentCountFilter from './PaymentCountFilter';

registerAllModules();
const intervalType = [{ name: "Daily", id: "daily" }, { name: "Weekly", id: "weekly" }, { name: "Monthly", id: "monthly" }]
const subscriptionTypes = [{ name: "All", id: 0 }, { name: "New", id: 1 }, { name: "Renewal", id: 2 }, { name: "Resume", id: 3 }]

function convertData(chartData: any, keyName: any) {
    let graphData: any = []
    if (chartData?.includes(undefined)) {
        graphData = chartData?.filter((ele: any) => ele != undefined)
    } else {
        graphData = chartData
    }

    let kyes = Object.keys(graphData[0] || {})
    return {
        categories: kyes,
        series: graphData?.map((element: any) => {
            let keys = Object.keys(element)
            return {
                name: element[keyName],
                data: keys.map((item: any) => element[item])
            }
        })
    }
}

export const PaymentCounts = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: MomentDate(0),
        type: "daily",
        category: "",
        subscription_type: 0
    })

    async function getPaymentCountsList() {
        setFilteredSelectedRows([])
        setPending(true)
        const ticketCountData = await postRequest(URL_EndPoints(null)?.getPaymentsCountBasedOnFrequencyOrMonths, payload, setPending)
        setColumns(ticketCountData?.data?.columns || [])
        setFilterData(ticketCountData?.data?.data || [])
        setData(ticketCountData?.data?.data || [])
    }

    useEffect(() => {
        getPaymentCountsList()
    }, [])

    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])

    const searchHandler = () => {
        getPaymentCountsList()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    const [showChart, setShowChart] = useState(false)

    // hot table code ------------------------------------
    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };

    const cellRenderer = (...args: any) => {
        const [instance, td, row, col, prop, value, cellProperties] = args

        Handsontable.renderers.TextRenderer.apply(this, args);
        const rowData = instance.getSourceDataAtRow(row);
        const isParentRow = rowData?.__children;
        if (isParentRow && prop === 'category') {
            td.style.backgroundColor = '#059df0';
            td.style.color = 'white';
        }
    };


    const shortWords = (sentence: any, index: any) => {
        if (index < 1) { return removeUnderScore(sentence) }
        if (sentence == "__children") return
        else {
            let str = sentence?.split(" ")?.[1]
            return str
        }
    }

    return (
        <>
            <KTCardBody>
                <div className='mb-4 fs-4 fw-semibold'>Payment Count {payload?.category ?? "`⚠️ No List Selected`"} Wise </div>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                        {
                            <DropDownFilterMenu
                                ParentData={{
                                    handleActions: searchHandler,
                                    payloads: payload,
                                    onChangeHandler: onChangeHandler,
                                    intervalType: intervalType,
                                    subscriptionTypes: subscriptionTypes,
                                    defaultInterval: { label: "Daily", value: "daily" },
                                    defaultListType: { label: "All", value: "" },
                                    defaultSubscriptionType: { label: "All", value: 0 },
                                    showListType: true
                                }}
                                Component={PaymentCountFilter}
                            ></DropDownFilterMenu>
                        }
                    </RightBox>
                </MainContainerWrapper>
                {/* hint components */}
                <ShowFilterHint payload={payload} />

                {pending ? <IsLoading /> : <div >

                    <HotTable
                        style={{ zIndex: 1 }}
                        data={filterData}
                        className='paymentCounts'
                        filters={true}
                        dropdownMenu={true}
                        colWidths={colWidthsHotTable(columns, 1, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        title='Payment Counts'
                        rowHeaders={true}
                        colHeaders={columns.map((ele: any, index: any) => shortWords(ele, index))}
                        fixedColumnsLeft={1}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                        ref={hotTableComponentRef}
                        preventOverflow="horizontal"
                        nestedRows={true}
                        bindRowsWithHeaders={true}
                        cells={(row, col) => {
                            return {
                                renderer: cellRenderer,
                            };
                        }}
                    />
                </div>}

            </KTCardBody>

            {
                showChart ? <ShowTicketCountChartModal
                    chartData={convertData(getFilteredRowDataNew2(filteredSelectedRows, filterData), payload?.category ?? "source")}
                    show={showChart}
                    setShow={setShowChart}
                    payload={payload}
                    title={`Show leads count chart ${payload?.category ?? "`⚠️ No List Selected`"}`}
                /> : <></>
            }

        </>
    );
};







