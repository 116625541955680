/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import React from "react"
import '../styles/common.css'
import Not_Found_Data from '../../Not_Found_Data'
import HoverToolTips from '../../CustomToolTips/OnHoverToolTips/HoverToolTips'
import moment from 'moment'
import AudioPalyerModel from '../../BootrapDropdown/AudioPalyerModel'
import AudioPlayer from '../../AudioPlayer'
import { postRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { fetchCallHistoryUtil } from '../../components/Utility'
import IsLoading from '../../IsLoading/IsLoading'
type Props = {
    isDrawer?: boolean
    loading?: any
    setCallHistoryData?: any
    callHistoryData?: any
    cleanerid?: any
    keyName?: any
}

const ChampsCallLogs: FC<Props> = ({ callHistoryData, setCallHistoryData, cleanerid, keyName }: any) => {
    const isDrawer = false
    const [audio, setAudio] = useState<any>("");
    const [palaying, setpalaying] = useState<any>(null);
    const [toggle, setToggle] = React.useState(false)
    const [ShowDataRefs, setShowDataRefs] = React.useState(0)
    const [pending, setPending] = React.useState(false)

    useEffect(() => {

        if (cleanerid && keyName == 3) {
            fetchCallHistoryUtil([cleanerid], setCallHistoryData, setPending, "cleaner")
        }

    }, [keyName, cleanerid])

    const handlChange = (ShowData: any) => {
        setToggle((prev) => prev === true && ShowDataRefs === ShowData ? false : true)
        setShowDataRefs(ShowData)
    }

    const hanlePause = (event: any, url: any) => {
        if (url == audio) {
            setpalaying(false)
        }
    }

    const handlePlay = (event: any, url: any) => {
        setpalaying(url === audio && true)
    }

    const handleClickToPlay = (url: any) => {
        setAudio(url)
    };

    return (
        <>
            {
                pending ? <IsLoading /> : <div
                    className='customer-detail-bodys'
                    id={'kt_chat_messenger_body'}
                >
                    <div
                        className={clsx('scroll-y me-n5 pe-5  max', { 'h-lg-auto': !isDrawer })}
                        data-kt-element='messages'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies={
                            '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                        }
                        data-kt-scroll-wrappers={
                            '#kt_content, #kt_chat_messenger_body'
                        }
                    >
                        {
                            callHistoryData?.length > 0 ?
                                <>
                                    <div className='table  margins'>
                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fs-8 fw-bold'>
                                                    <th className='min-w-25px'>Recording</th>
                                                    <th className='min-w-150px'>Call Duration</th>
                                                    <th className='min-w-150px'>Start - End Time </th>
                                                    <th className='min-w-140px'>Call Status</th>
                                                    <th className='min-w-120px'>Call From </th>
                                                    <th className='min-w-100px'>Call To </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    callHistoryData?.map((element: any) => {
                                                        let callConersation: any = element?.ConversationDuration / 60
                                                        let times: any = callConersation?.toString()?.split('.')
                                                        let minute = times?.length > 0 ? times[0] : 0
                                                        let seconds = times?.length > 1 ? times[1]?.toString()[0] : 0
                                                        return (

                                                            <tr className='margins'>
                                                                {
                                                                    element?.RecordingUrl ? <td onClick={() => handleClickToPlay(element?.RecordingUrl)} id="plays">
                                                                        <AudioPalyerModel ParentData={{ element, handlePlay, hanlePause, palaying }} Components={AudioPlayer} audio={audio === element?.RecordingUrl ? audio : ""} setAudio={setAudio} ></AudioPalyerModel>
                                                                    </td> : <td><button className='btn btn text-dark  ms-1'><i className="bi bi-x-circle text-danger fs-1"></i></button></td>
                                                                }

                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='d-flex justify-content-start flex-column'>
                                                                            <span className={`text-${element?.ConversationDuration > 0 ? "success" : "danger"} text-hover-primary fs-8 fw-bold`}>
                                                                                {element?.ConversationDuration == 60 ? "1 Min" : element?.ConversationDuration == 0 ? "0 Sec" : element?.ConversationDuration > 0 && element?.ConversationDuration < 60 ? `${element?.ConversationDuration} Sec` : times?.length > 0 ? `${minute} Min ${seconds} Sec` : "0 Sec"}
                                                                            </span>
                                                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                                {/* Call Description */}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>


                                                                <td>
                                                                    <span className={`text-dark text-hover-primary d-block fs-8 fw-bold`}>
                                                                        {moment(element?.StartTime).format("HH:mm:ss")} {" - "}
                                                                        {moment(element?.EndTime).format("HH:mm:ss")}
                                                                    </span>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                        {moment(element?.StartTime).format("YYYY-MM-DD")}

                                                                    </span>
                                                                </td>


                                                                <td>
                                                                    <span className={`text-${element?.Status === "completed" ? "success" : element?.Status === "failed" ? "warning" : "primary"}  text-hover-primary d-block fs-8 fw-bold`}>
                                                                        {element?.Status}
                                                                    </span>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                        {/* {moment(element?.DateUpdated).format('MM/DD/YYYY')} */}
                                                                    </span>
                                                                </td>


                                                                <td>
                                                                    <div className='  d-block fs-8 fw-bold'>
                                                                        <HoverToolTips uniqueId={element.id} title={element?.FromDetails?.name} showData={element?.From} classeTitle={"text-hover-dark text-primary me-2"} classeShowData={"text-danger"} handlChange={handlChange} ShowDataRefs={ShowDataRefs} toggle={toggle} />
                                                                        <span className='text-hover-dark text-muted'>  {element?.FromDetails?.type}</span>
                                                                    </div>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                        {/* {moment(element?.DateUpdated).format('MM/DD/YYYY')} */}
                                                                    </span>
                                                                </td>


                                                                <td>
                                                                    <span className='text-success text-hover-primary d-block fs-8 fw-bold'>
                                                                        <div className='  d-block fs-8 fw-bold'>
                                                                            <HoverToolTips uniqueId={element.id + 1} title={element?.ToDetails?.name} showData={element?.To} classeTitle={"text-hover-dark text-primary me-2"} classeShowData={"text-danger"} handlChange={handlChange} ShowDataRefs={ShowDataRefs} toggle={toggle} />
                                                                            <span className='text-hover-dark text-muted'>  {element?.ToDetails?.type}</span>
                                                                        </div>
                                                                    </span>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                        {/* {moment(element?.DateUpdated).format('MM/DD/YYYY')} */}
                                                                    </span>
                                                                </td>


                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                <Not_Found_Data headerTitle={"No Call Logs Found"}></Not_Found_Data>
                        }
                    </div>
                </div>
            }
        </>
    )
}
export { ChampsCallLogs }
