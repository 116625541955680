import React, { useState, useEffect, useReducer, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import useOwnMoinState from './useOwnMoinState';
import { initial_rewards_states, rewardsTaskStates } from '../admins/ad_states/rewardsAndPenalties';
import { Button } from 'react-bootstrap';
import BulkApproveForm from './forms/BulkApproveForm';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';


const RewardAndPenaltiesList = ({ filterDataOutsource }: any) => {

  const [storeFilterList, setStoreFilterList] = useOwnMoinState({})
  const [filterkey, setFilterKey] = useOwnMoinState({})
  const [selectedFilterData, setSelectedFilterData] = useOwnMoinState([])
  const [payload, setPayload] = useOwnMoinState(initialPayload(false, {}))
  const [sortedColumns, setSortedColumns] = useOwnMoinState(columns(handleFetchAllCallLogs));
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [rewardNdPenaltiesStates, setLocalStateDispatch] = useReducer(rewardsTaskStates, initial_rewards_states);

  async function InvokedRendered(payloads: any, setloading: any) {
    setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
    const { data } = await postRequest(URL_EndPoints(null)?.getReqApprovalTrackerList, payloads, null)
    setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: data?.data })
    setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: data?.recordsTotal })
    setLocalStateDispatch({ type: "RTL_FILTER", payload: data?.filter?.RequestApprovalList })
    setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {

    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload

  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    // setLocalStateDispatch({ type: "RTL_TOTAL_ROWS", payload: 10 })
    await InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search, rewardNdPenaltiesStates?.sortby, rewardNdPenaltiesStates?.direction, rewardNdPenaltiesStates?.page, rewardNdPenaltiesStates?.perPageSize), null)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setLocalStateDispatch({ type: "RTL_SORT_BY", payload: column.sortField })
    setLocalStateDispatch({ type: "RTL_DIRECTION", payload: sortDirection })
    InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search, column.sortField, sortDirection, rewardNdPenaltiesStates?.page, rewardNdPenaltiesStates?.perPageSize), null)
  };

  const handlePageChange = (page: any) => {
    setLocalStateDispatch({ type: "RTL_PAGE", payload: page })
    InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search, rewardNdPenaltiesStates?.sortby, rewardNdPenaltiesStates?.direction, page, rewardNdPenaltiesStates?.perPageSize), null)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setLocalStateDispatch({ type: "RTL_PER_PAGE_SIZE", payload: newPerPageSize })
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      rewardNdPenaltiesStates?.search && setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
      rewardNdPenaltiesStates?.search && InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search && rewardNdPenaltiesStates?.search, rewardNdPenaltiesStates?.sortby, rewardNdPenaltiesStates?.direction, rewardNdPenaltiesStates?.page, rewardNdPenaltiesStates?.perPageSize), null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [rewardNdPenaltiesStates?.search])

  useEffect(() => {
    let { isFilter, rewardAndPenaltiesFilter } = filterDataOutsource || {}
    !rewardNdPenaltiesStates?.search && !isFilter && InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search, rewardNdPenaltiesStates?.sortby, rewardNdPenaltiesStates?.direction, rewardNdPenaltiesStates?.page, rewardNdPenaltiesStates?.perPageSize), null)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: rewardAndPenaltiesFilter }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: rewardAndPenaltiesFilter })
  }, [
    rewardNdPenaltiesStates?.search,
    filterDataOutsource?.rewardAndPenaltiesFilter,
    filterDataOutsource?.isFilter,
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(rewardNdPenaltiesStates?.search, rewardNdPenaltiesStates?.sortby, rewardNdPenaltiesStates?.direction, rewardNdPenaltiesStates?.page, rewardNdPenaltiesStates?.perPageSize), null);
  }, [selectedFilterData?.length]);

  function handleFetchAllCallLogs() {
    setLocalStateDispatch({ type: "RTL_FETCH_CALL_LOG_TOGGLER", payload: Math.random() })
  }

  useEffect(() => {
    rewardNdPenaltiesStates?.fetchCallToggler && setLocalStateDispatch({ type: "RTL_TEMP_FILTERED_DATA", payload: rewardNdPenaltiesStates?.filterData })
  }, [rewardNdPenaltiesStates?.fetchCallToggler])


  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setLocalStateDispatch({ type: "RTL_PENDING", payload: true });
      const clone = rewardNdPenaltiesStates?.filterDataTemp?.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, null);

      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }

      setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: clone })
      setLocalStateDispatch({ type: "RTL_PENDING", payload: false });

    }
    rewardNdPenaltiesStates?.filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [rewardNdPenaltiesStates?.filterDataTemp])

  const handleSelectedRowsChange = (selectedRows: any) => {
    setLocalStateDispatch({ type: "RTL_FILTERED_SELECTED_ROWS", payload: selectedRows?.selectedRows })
  };

  const [showBulkApproveModal, setShowBulkApproveModal] = useState(false)



  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)
    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getReqApprovalTrackerList")
  }, [])

  return (
    <>
      <div>

        <DataTable
          title={!filterDataOutsource?.isFilter && "Reward and Penalties"}
          selectableRows
          progressPending={rewardNdPenaltiesStates?.pending}
          columns={reorderColumns(sortedColumns, "rewardsListTaskReorderedItem")}
          data={rewardNdPenaltiesStates?.filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "rewardsListTaskReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          pagination
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={rewardNdPenaltiesStates?.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>

                  <input
                    type="text"
                    placeholder='Search Here'
                    value={rewardNdPenaltiesStates?.search}
                    onChange={(e: any) => setLocalStateDispatch({ type: "RTL_SEARCH", payload: e.target.value })}
                    className='w-100 form-control align-start'
                  />
                </div>
                <div className='d-flex align-items-center justify-content-end '>
                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={rewardNdPenaltiesStates?.filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      listApiUrl={listApiUrl("getReqApprovalTrackerList")}

                    />
                    {/* <ToggleColumns columns={[{ name: "RENEWAL DATE", id: "RENEWAL DATE" }]} from="RENEWAL_TASK_LIST" /> */}

                  </div>
                </div>
              </div>

              <div className="w-100  d-flex">
                <div className='fiteredSelectedRows w-80 ml-2'>
                  {
                    rewardNdPenaltiesStates?.filteredSelectedRows?.length > 0 ?
                      <div className=''>
                        <Button className='btn-sm' onClick={() => setShowBulkApproveModal(true)}>Bulk Approve {rewardNdPenaltiesStates?.filteredSelectedRows?.length} Champs</Button>
                      </div>
                      : <div></div>
                  }
                </div>
              </div>

            </>
          }
        />

      </div>

      {
        showBulkApproveModal ?
          <BulkApproveForm
            show={showBulkApproveModal}
            setShow={setShowBulkApproveModal}
            filteredSelectedRows={rewardNdPenaltiesStates?.filteredSelectedRows}
            setToggleClearRows={setToggleClearRows}
            toggledClearRows={toggledClearRows}
          /> : <></>
      }

    </>
  )
}

export default RewardAndPenaltiesList
