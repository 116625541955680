import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useReducer, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import IsLoading from '../../consts/IsLoading/IsLoading'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { CardCountWidget } from './CardCountWidget'
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu'
import { NewDailyJobDashboardFiltration } from './NewDailyJobDashboardFiltration'
import { newDailyJobDashboardStates, newDailyJobDashboardStatesReducer } from '../admins/ad_states/newDailyJobDashboardStates'
import Old_JobList from '../Old_JobList/Old_JobList'
import { Lists } from './Lists'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { alert_columns } from './columns/AlertColumns'
import { LeaveColumn } from './columns/LeaveColumn'
import { inactive_columns } from './columns/InactiveColumns'
import { newDailyJobListTabwiseKeyValue } from '../../consts/CommonData'
import { KTCardBody } from '../../../_metronic/helpers'
import { useDispatch } from 'react-redux'
import MomentDate from '../../consts/MomentDate'
import SingleSelectSearchDetails from '../../consts/Select/SingleSelectSearchDetails'
import SingleSelectSearchCategory from '../../consts/Select/SingleSelectSearchCategory'
import Refresh from '../../consts/Refresh'
import AreaManagerWalletList from './areaManagerWallets/AreaManagerWalletList'
import AreaManagerCustomerReview from './areaManagerCustomerReviewList/AreaManagerCustomerReview'
import TaskListController from '../taskLists/TaskListController'
import AreaManagerDailyDashboard from '../areaManagerDailyDashboard/AreaManagerDailyDashboard'

export const NewDailyJobDashboard = () => {
	const dispatch = useDispatch()
	const [newDailyJobDashboardStatesData, setLocalStateDispatch] = useReducer(newDailyJobDashboardStatesReducer, newDailyJobDashboardStates);
	const [key, setKey] = useState<number>(1)
	const [pending, setPending] = useState(false)
	const handleSelect = (key: any) => {
		setKey(key);
	}

	const [payload, setPayload] = useState<any>({
		date: MomentDate(0),
		supervisorid: null,
		type: null,
		customerVintage: null,
	})

	async function contentApiDataLoad() {

		setLocalStateDispatch({ type: "RTL_PENDING", payload: true })
		let newPayload = { ...payload }
		newPayload.customerVintage = +newPayload.customerVintage
		if (!newPayload?.customerVintage) {
			newPayload.customerVintage = 0
		}
		const newDataPromise = postRequest(URL_EndPoints()?.getNewDailyDashboardData, newPayload, null)
		const champDataPromise = postRequest(URL_EndPoints()?.getNewDailyDashboardChampData, newPayload, null)
		const [newData, champData]: any[] = await Promise.allSettled([newDataPromise, champDataPromise])

		setLocalStateDispatch({ type: "RTL_FILTERED_DATA", payload: newData?.value?.data?.data })
		setLocalStateDispatch({ type: "TDL_CHAMP_CARD_DATA", payload: champData?.value?.data?.data })
		setLocalStateDispatch({ type: "RTL_PENDING", payload: false })
	}

	async function initialData() {

		const getSupervisorListData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
		setLocalStateDispatch({ type: "RTL_SUPER_VISOR_LIST_DATA", payload: getSupervisorListData?.data?.data })

		const getAlJobTypes = await getRequest(URL_EndPoints(null)?.getAlJobTypes, null)
		setLocalStateDispatch({ type: "RTL_JOB_TYPE_LIST_DATA", payload: getAlJobTypes?.data?.data })

		const getSubscriptionRenewalReason: any = await postRequest(URL_EndPoints(null)?.getSubscriptionRenewalReason, {}, null)
		let inactiveReasonData: any = []
		let alertReasonData: any = []
		getSubscriptionRenewalReason?.data?.data?.map((reason: any) => {
			if (reason.category_id == 2) {
				inactiveReasonData.push(reason)
			} else if (reason.category_id == 3) {
				alertReasonData.push(reason)
			}
		})

		dispatch({ type: "INACTIVE_DATA", payload: inactiveReasonData })
		dispatch({ type: "ALET_REASON", payload: alertReasonData })
	}

	useEffect(() => {
		initialData()
		contentApiDataLoad()
	}, [])

	const handleSearch = async () => {
		setLocalStateDispatch({ type: "TDL_FILTER_DATE", payload: payload?.date })
		setLocalStateDispatch({ type: "TDL_FILTER_JOB_TYPE", payload: payload?.supervisorid })
		setLocalStateDispatch({ type: "TDL_FILTER_SUPERVISOR", payload: payload?.type })
		setLocalStateDispatch({ type: "TDL_FILTER_CUSTOMER_VINTAGE", payload: payload?.customerVintage })
		contentApiDataLoad()
	}

	const handleTabClick = (filtereData: any = {}, tabKey: any = undefined, isRedirect: any = undefined) => {
		let {
			attendencedatefrom,
			attendencedateto,
			rates,
			attendance_ids,
			new_job_status_id,
			new_job_sub_status_id,
			attribute_id,
			champIds
		} = filtereData

		let filterDataList: any = {}

		if (attendencedatefrom && attendencedateto) {
			filterDataList["attendencedatefrom"] = attendencedatefrom
			filterDataList["attendencedateto"] = attendencedateto
		}

		if (rates?.length > 0) {
			filterDataList["rates"] = rates
		}

		if (new_job_status_id || new_job_status_id == 0) {
			filterDataList["job_status_id"] = true
			filterDataList["new_job_status_id"] = new_job_status_id
		}

		if (new_job_sub_status_id || new_job_sub_status_id == 0) {
			filterDataList["new_job_sub_status_id"] = new_job_sub_status_id
		}

		if (attribute_id || attribute_id == 0) {
			filterDataList["attribute_id"] = attribute_id
		}

		if (attendance_ids?.length > 0) {
			filterDataList["attendance_ids"] = attendance_ids
		}

		// menu wise filter  --------------
		if (!attendencedatefrom && !attendencedateto && payload?.date) {
			filterDataList["attendencedatefrom"] = payload?.date
			filterDataList["attendencedateto"] = payload?.date
		}

		if (payload?.supervisorid) {
			filterDataList["supervisorId"] = payload?.supervisorid
		}

		if (payload?.type) {
			filterDataList["type"] = payload?.type
		}

		if (attendance_ids?.length == 0) {
			filterDataList["attendance_ids"] = false
		}

		if (Object.keys(filterDataList)?.length > 0) {
			filterDataList.isFilter = true
			filterDataList.isExternalApi = "allChampsJobsV2"
			filterDataList.customerVintage = +payload?.customerVintage
		}

		if (tabKey == 9) {
			filterDataList["taskListFilter"] = { taskListFilter: filtereData, isFilter: true }
		}

		setLocalStateDispatch({ type: "NEW_DAILY_DASHBOARD_OPEN_FILTER_DATA", payload: filterDataList })
		let type = newDailyJobListTabwiseKeyValue[tabKey]
		type && FetchFilterData(type, { champIds: champIds, date: payload.date, type: payload.type })

		// code for dynamic list filter  


		setKey(tabKey ?? 1);
	}

	const handleChangeInput = (event: any, name: any) => {
		setPayload({ ...payload, [name]: event ? event?.value : event })
	}

	const [alertList, setAlertList] = useState<any>([])
	const [filterAlertList, setFilteralertList] = useState<any>([])
	const [alertSearch, setAlertSearch] = useState<any>("")

	const [absentList, setAbsentList] = useState<any>([])
	const [filterAbsentList, setFilterAbsentList] = useState<any>([])
	const [absentSearch, setAbsentSearch] = useState<any>("")

	const [leaveList, setLeaveList] = useState<any>([])
	const [filterLeaveList, setFilterLeaveList] = useState<any>([])
	const [leaveSearch, setLeaveSearch] = useState<any>("")

	const [inactiveList, setInactiveList] = useState<any>([])
	const [filterInactiveList, setFilterInactiveList] = useState<any>([])
	const [inactiveSearch, setInactiveSearch] = useState<any>("")

	useEffect(() => {
		const updatedData = AllFielSearch(alertList, alertSearch)
		setFilteralertList(updatedData)
	}, [alertSearch])

	useEffect(() => {
		const updatedData = AllFielSearch(absentList, absentSearch)
		setFilterAbsentList(updatedData)
	}, [absentSearch])

	useEffect(() => {
		const updatedData = AllFielSearch(leaveList, leaveSearch)
		setFilterLeaveList(updatedData)
	}, [leaveSearch])

	useEffect(() => {
		const updatedData = AllFielSearch(inactiveList, inactiveSearch)
		setFilterInactiveList(updatedData)
	}, [inactiveSearch])

	const FetchFilterData = async (type: any, body: any) => {
		setPending(true)
		const FilterDataList = await postRequest(URL_EndPoints()?.getChampListNewDailyDashboard, body, setPending)
		storeListData(type, FilterDataList?.data?.data)
	}

	const storeListData = (type: string, data: any) => {
		switch (type) {
			case "IS_ALERT":
				setAlertList(data)
				setFilteralertList(data)
				return
			case "IS_ABSENT":
				setAbsentList(data)
				setFilterAbsentList(data)
				return
			case "IS_LEAVE":
				setLeaveList(data)
				setFilterLeaveList(data)
				return
			case "IS_INACTIVE":
				setInactiveList(data)
				setFilterInactiveList(data)
				return
			default:
				return
		}
	}

	const [areaManagerJobDashboardCallApi, setAreaManagerJobDashboardCallApi] = useState(true)

	return (
		<>
			<KTCardBody className='card py-4'>
				{
					<>
						<Tabs activeKey={key} onSelect={handleSelect} id="controlled-tab-example">
							<Tab eventKey={1} title="New Daily Dashboard">

								{
									<>
										<div className='d-flex justify-content-start row mt-2'>
											<div className=' mb-2 col col-md-2 col-sm-6 col-lg-2' style={{ position: "relative" }}>
												<label htmlFor="Date fw-semibold">Job Date</label>
												<div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
													<input
														className='form-control col-12'
														type="date"
														name="date"
														id="date"
														autoComplete='off'
														style={{ height: "39px" }}
														onChange={(event) => handleChangeInput(event.target, "date")}
														value={payload.date}
													/>
												</div>
											</div>

											<div className="mb-2 col col-md-3 col-sm-6 col-lg-3" key={"ewew2"}>
												<label htmlFor="Date fw-semibold">AM Filter</label>
												<SingleSelectSearchDetails
													handleChangeInputData={handleChangeInput}
													HeaderTitle="Select Supervisor"
													SelectData={newDailyJobDashboardStatesData?.supervisorListData}
													DynamicKey={"first_name"}
													DynamicKey2={"last_name"}
													DynamicKey3={"phone"}
													id={"id"}
													name="supervisorid"
												/>
											</div>

											<div className="mb-2 col col-md-2 col-sm-6 col-lg-2" key={43432432}>
												<label htmlFor="Date fw-semibold">Service Type</label>
												<SingleSelectSearchCategory
													handleChangeInputData={handleChangeInput}
													HeaderTitle='Service Type'
													SelectData={newDailyJobDashboardStatesData?.jobTypeListData}
													DynamicKey={'name'}
													id={'id'}
													name='type'
												></SingleSelectSearchCategory>
											</div>

											<div className=' mb-2 col col-md-2 col-sm-6 col-lg-2' style={{ position: "relative" }}>
												<label htmlFor="Date fw-semibold">Customer Vintage</label>

												<div className='input-group mb-3 d-flex justify-content-between gap-2' style={{ height: "40px" }}>
													<input
														className='form-control col-12'
														type="number"
														name="customerVintage"
														// id="date"
														placeholder='Customer Vintage'
														autoComplete='off'
														style={{ height: "39px" }}
														onChange={(event) => handleChangeInput(event.target, "customerVintage")}
														value={payload.customerVintage}
													/>
												</div>
											</div>

											<div className="mb-2 col col-md-3 col-sm-6 col-lg-3 text-end d-flex  justify-content-end align-items-center">
												<Refresh refreshNow={handleSearch} />
												<button className='btn  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-2 px-1 filterBtn text-center fw-bold'
													onClick={handleSearch}
													style={{
														height: "39px",
														width: "40%",
														margin: "0",
														marginLeft: "9px"
													}}
												> <i className="bi bi-filter p-1 fs-5  fw-bold"  ></i> Apply</button>
											</div>


										</div>
										{
											newDailyJobDashboardStatesData?.pending ? <IsLoading></IsLoading> :
												<div className='row m-4 g-5 g-xl-8 justify-content-center'>

													{newDailyJobDashboardStatesData?.champCardData?.map((element: any) => {
														return <div className='col-xl-2 border-jobd job-section'>
															<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} />
														</div>
													})}

													{newDailyJobDashboardStatesData?.filterData?.map((element: any) => {
														return <div className='col-xl-2 border-jobd job-section'>
															<CardCountWidget className='' color='primary' data={element} handleTabClick={handleTabClick} />
														</div>
													})}
												</div>
										}
									</>
								}
							</Tab>

							<Tab eventKey={2} title="Champ List">
								{newDailyJobDashboardStatesData?.pending ? <IsLoading></IsLoading> :
									key == 2 ? <div className="container container_custom" key="alertSearch1235454543">
										<Lists
											filteredDataList={filterAlertList}
											search={alertSearch}
											setSearch={setAlertSearch}
											columns={alert_columns}
											pending={pending}
											keyName="champList"
										/>
									</div> : <></>
								}
							</Tab>

							<Tab eventKey={3} title="Absent List" >
								{newDailyJobDashboardStatesData?.pending ? <IsLoading></IsLoading> :
									key == 3 ? <div className="container container_custom" key="absentSearch12123443">
										<Lists
											filteredDataList={filterAbsentList}
											search={absentSearch}
											setSearch={setAbsentSearch}
											columns={LeaveColumn}
											pending={pending}
											keyName="absentList"

										/>
									</div> : <></>
								}
							</Tab>

							<Tab eventKey={4} title="Leave List">
								{newDailyJobDashboardStatesData?.pending ? <IsLoading></IsLoading> :
									key == 4 ? <div className="container container_custom" key="leaveSearch1312122332">
										<Lists
											filteredDataList={filterLeaveList}
											search={leaveSearch}
											setSearch={setLeaveSearch}
											columns={LeaveColumn}
											pending={pending}
											keyName="LeaveList"

										/>
									</div> : <></>
								}
							</Tab>

							<Tab eventKey={5} title="Inactive List">
								{newDailyJobDashboardStatesData?.pending ? <IsLoading></IsLoading> :
									key == 5 ? <div className="container container_custom" key="inactiveSearch12121212243424">
										<Lists
											filteredDataList={filterInactiveList}
											search={inactiveSearch}
											setSearch={setInactiveSearch}
											columns={inactive_columns}
											pending={pending}
											keyName="inactiveList"

										/>
									</div> : <></>
								}
							</Tab>

							<Tab eventKey={6} title="Job List" >
								{false ? <IsLoading></IsLoading> :
									<div className="container container_custom" key={"jobDashboard"}>
										{
											key == 6 ? <Old_JobList filterDataOutSource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData} /> : <></>
										}
									</div>

								}
							</Tab>

							<Tab eventKey={7} title="A.M Wallet" >
								<div className='w-125'>
									{
										false ? <IsLoading></IsLoading> : key == 7 ? <AreaManagerWalletList filterDataOutSource={{ date: payload?.date, supervisorid: payload?.supervisorid }} /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={8} title="A.M Customer Review" >
								<div className='w-125'>
									{
										false ? <IsLoading></IsLoading> : key == 8 ? <AreaManagerCustomerReview filterDataOutSource={{ startDate: payload?.date, endDate: payload?.date, supervisorId: payload?.supervisorid }} /> : <></>
									}
								</div>
							</Tab>

							<Tab eventKey={10} title="AMs Job Dashboard" >
								<div className='w-125'>
									{
										<AreaManagerDailyDashboard areaManagerJobDashboardCallApi={areaManagerJobDashboardCallApi} setAreaManagerJobDashboardCallApi={setAreaManagerJobDashboardCallApi} />
									}
								</div>
							</Tab>

							<Tab eventKey={9} title="Task List" >
								<div className='w-125'>
									{
										false ? <IsLoading></IsLoading> : key == 9 ? <TaskListController filterDataOutsource={newDailyJobDashboardStatesData?.newDailyDashboardOpenFilterData?.taskListFilter} /> : <></>
									}
								</div>
							</Tab>
						</Tabs>
					</>
				}
			</KTCardBody>
		</>
	)
}
