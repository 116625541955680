import GChatBTN from "../../consts/Chats/ChatButtons/GChatBTN";
import { getName } from "../admins/adminUtils";

export const AgentAllotmentListColumns = (categoryListData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        selector: (row: any) => row?.id ?? "NA",
        sortable: true,
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "AGENT",
        cell: (row: any) => {
            return getName(row?.agentAllotedItemAdmin)
        },
        sortable: true,
        width: "200px",
        sortFunction: (row1: any, row2: any) => {
            const field1: any = row1?.agentAllotedItemAdmin?.first_name;
            const field2: any = row2?.agentAllotedItemAdmin?.first_name;
            if (field1 > field2) {
                return 1;
            }
            if (field2 > field1) {
                return -1;
            }
            else {
                return 0;
            }
        },

    },
    {
        id: 10,
        reorder: true,
        name: "ROLE",
        cell: (row: any) => row?.agentAllotedItemAdmin?.role,
        // sortable: true,
        width: "120px",
        sortFunction: (row1: any, row2: any) => {
            const field1: any = row1?.agentAllotedItemAdmin?.role;
            const field2: any = row2?.agentAllotedItemAdmin?.role;
            if (field1 > field2) {
                return 1;
            }
            if (field2 > field1) {
                return -1;
            }
            else {
                return 0;
            }
        },

    },
    {
        id: 3,
        reorder: true,
        name: "TYPE",
        selector: (row: any) => row?.item_type  ? row?.item_type  : "NA",
        sortable: true,
        width: "120px"

    },

    {
        id: 4,
        reorder: true,
        name: "VALUE",
        selector: (row: any) => row?.item_value  ? row?.item_value  : "NA",
        sortable: true,
        width: "120px"

    },
    {
        id: 5,
        reorder: true,
        name: "UNIT",
        selector: (row: any) => row?.unit  ?row?.unit   : "NA",
        sortable: true,
        width: "120px"

    },
    {
        id: 6,
        reorder: true,
        name: "QUANTITY",
        selector: (row: any) => row?.quantity  ? row?.quantity  : "NA",
        sortable: true,
        width: "100px"
    },
    

]

