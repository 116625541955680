
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SingleSelectSearchCategoryDefault from '../../Select/SingleSelectSearchCategoryDefault';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { getRequest, postRequestWithFile } from '../../Server/Requests';
import { createCouponCode } from '../../CommonData';
import { couponOffer } from '../../../modules/admins/adminConstant';

const AddNewMasterOffersForm = (props: any) => {
    const PopUpPostFormOpen = props?.PopUpPostFormOpen
    const handleClose = props?.handleClose
    const tableRefresh = props?.tableRefresh

    const customerDetailData = props?.customerDetailData

    const [payload, setPayload] = useState<any>({
        offerName: "",
        type: "percentage",
        amount: 0,
        coupon: "",
        customerid: customerDetailData?.id,
        // status: 0,

    })


    // to close pop up form
    const handleSubmit = async (e: any) => {
        e.preventDefault();


        if (payload?.amount > 0) {
            const response = await postRequestWithFile(URL_EndPoints()?.createInstanntCouponPercentage, payload, null)
            if (response.status == 200) {
                toast("Offer created successfully.")
            }

            tableRefresh();
            handleClose()
        }

    }
    const handleChnageInputData = (e: any, name = null): any => {

        if (name == null) {
            if (e?.target?.name == "status") {
                setPayload({
                    ...payload,
                    [e?.target?.name]: e?.target?.checked == true ? 1 : 0

                })
            }

            else {
                setPayload({
                    ...payload,
                    [e?.target?.name]: e?.target?.value,

                })
            }
        } else {

            setPayload({
                ...payload,
                [name]: e?.value,
            })
        }
    }




    const createNewCoupon = () => {
        let couponCode = createCouponCode(10)
        setPayload({
            ...payload,
            ['coupon']: couponCode,

        })
    }

    return (
        <Modal size="lg" show={PopUpPostFormOpen} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Add Job Offers</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Offer Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Offer Name" name="offerName" onChange={handleChnageInputData} value={payload?.offerName} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Select Amount </Form.Label>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChnageInputData}
                            HeaderTitle="Select Amount"
                            DynamicKey={"name"}
                            id={"type"}
                            name="amount"

                            SelectData={couponOffer}

                        ></SingleSelectSearchCategoryDefault>
                    </Form.Group>

                    {/* <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Coupon Code </Form.Label>
                        <div className="d-flex justify-content-between">
                            <Form.Control type="text" placeholder="Enter Coupon" name="coupon" onChange={handleChnageInputData} value={payload?.coupon} />
                            <button type="button" className='btn btn-sm btn-secondary ml-1' onClick={createNewCoupon}>
                                generate coupon code
                            </button>
                        </div>

                    </Form.Group> */}
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>

            </Modal.Body>
        </Modal >

    )
}

export default AddNewMasterOffersForm



