import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DueDateUpdateForm from "./DueDateUpdateForm";
export const DueDateUpdateDashboardForm = ({ row, DueDateModal, setDueDateModal }: any) => {
    // const dispatch = useDispatch()
    // const [openDueDateModal, setopenDueDateModal] = useState(DueDateModal)

    const handleCloseForm = () => {
        // dispatch({
        //     type: "MODAL_OPEN_DUE", payload: {
        //         toggle: false,
        //         id: null,
        //     }
        // })
        setDueDateModal(false)
    }

    // useEffect(() => {
    //     DueDateModal && setopenDueDateModal(true)
    // }, [DueDateModal])

    return <>

        {
            DueDateModal ?
                <Dialog
                    open={true}
                    onClose={handleCloseForm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DueDateUpdateForm
                        FilteredData={{
                            ticket_id: row?.id ? row?.id : "",
                            prev_due_date: row?.defaultDueDate ? moment(new Date(row?.defaultDueDate)).format('YYYY-MM-DD HH:mm:ss') : '',
                        }}
                        
                        handleCloseForm={handleCloseForm}
                        row={row}

                    />
                </Dialog>
                : ""
        }
    </>
}
