
const initialState = {
  userAllowedPermissionList: [],
}

export const adminReducer = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case "STORE_USER_ALLOWED_PERMISSION_LIST":
      return {
        ...state,
        userAllowedPermissionList: payload,
      }
    default:
      return state
  }
}


// const userAllowedPermissionList = useSelector((store:any)=>store.adminReducer.userAllowedPermissionList)
