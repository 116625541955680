import React, { useEffect, useState } from 'react'
import { AgentAllotmentListColumns } from './AgentAllotmentListColumns'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { getRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { AdminClientSidePaginationLists } from '../admins/adminClientSidePaginationLists/AdminClientSidePaginationLists'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton'
import { AllotmentFilter } from './AllotmentFilter'
import AdminForm from '../admins/adminForms/AdminForm'
import { couponOffer } from '../admins/adminConstant'


 const createAllotments = [
    {
        HeaderTitle: 'Select Agent Name',
        filterApi: 'getAdminList',
        label: {
            show: true,
            name: 'Select Agent Name',
        },
        valueType: 'select',
        inputType: '',
        displayFields: ["first_name", "last_name", "phone"],
        valueField: 'id',
        name: 'agentId',
        from: '',
        to: '',
        isRequired: true,
        errMessage: 'Agent name is required !',
        searchable: false,
        wa_number_id: null,
        userType: null,
    },
    {
        HeaderTitle: 'Select Amount ',
        filterApi: 'staticFormData',
        label: {
            show: true,
            name: 'Select Amount',
        },
        valueType: 'select',
        inputType: '',
        displayFields: ["name"],
        valueField: 'type',
        name: 'amount',
        from: '',
        to: '',
        isRequired: true,
        errMessage: 'Amount is required !',
        searchable: false,
        wa_number_id: null,
        userType: null,
    },
    {
        HeaderTitle: 'Quantity ',
        filterApi: '',
        label: {
            show: true,
            name: 'Quantity',
        },
        valueType: 'textBox',
        inputType: 'number',
        displayFields: [""],
        valueField: '',
        name: 'quantity',
        from: '',
        to: '',
        isRequired: true,
        errMessage: 'Quantity is required !',
        searchable: false,
        wa_number_id: null,
        userType: null,
    },
]

const AgentAllotmentList = () => {
    const [sortedColumns, setSortedColumns] = useState<any>(AgentAllotmentListColumns())
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [filterData, setFilterData] = useState<any>([])

    useEffect(() => {
        const filterSearchData = AllFielSearch(data, search)
        setFilterData(filterSearchData)
    }, [search])

    const getOfferAllotmentList = async () => {
        setPending(true)
        const response = await getRequest(URL_EndPoints()?.getAgentAllotments, setPending)
        setFilterData(response?.data?.data)
        setData(response?.data?.data)
    }

    useEffect(() => {
        getOfferAllotmentList()
    }, [])

    const [offerAllotment, setOfferAllotment] = useState(false)

    return (
        <>
            <AdminClientSidePaginationLists
                title="Agent Offer Allotment List"
                reorderColumnTitle="agentOfferAllotmentListReorderColumnItem"
                sortedColumns={sortedColumns}
                filterData={filterData}
                pending={pending}
                clearSelectedRows={false}
                totalRows={filterData?.length}
                conditionalRowStyles={{}}
                onChangePage={() => { }}
                onChangeRowsPerPage={() => { }}
                onSelectedRowsChange={() => { }}
                search={search}
                setSearch={setSearch}
                showFilter={false}
                pagination={true}
                ExpandedComponent={()=><></>}
                clientSideFilterComponent={AllotmentFilter}
                keyName={"agentOfferAllotmentList"}
                clientSideFilterData={{
                    // handleChangeInputData: onChangeHandler,
                    // handleActions: searchHandler,
                    // payloads: payload,
                    // supervisorListData: supervisorListData,
                }}
                SubHeaderComponentRightSection={() => (<>
                        <Refresh refreshNow={getOfferAllotmentList} />
                        <BulkUtilButton title="Create Offer Allotment" filteredSelectedRows="static" setShow={setOfferAllotment} />
                </>)}
                SubHeaderComponentActionButtons={() => (<></>)}
            />
           
           {
                offerAllotment ? <AdminForm
                    show={offerAllotment}
                    setShow={setOfferAllotment}
                    refreshNow={() => { }}
                    toastMessage="message"
                    formData={createAllotments}
                    staticFormData={couponOffer}
                    initialData={{
                        agentId: null,
                        amount: "",
                        quantity: null
                    }}
                    keyName="createOfferAllotmentForAgentForm"
                    formTitle={`Create Offer Allotment For Agent`}
                    formSubmitApiUrl="createOfferAllotmentForAgent"
                /> : <></>
            }

        </>
    )
}

export default AgentAllotmentList
